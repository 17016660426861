//@ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import AdminCard from "../components/AdminCard.tsx";
import ModalComponent from "../components/ModalComponent.tsx";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  query,
  collection,
  getDocs,
  doc,
  updateDoc,
  DocumentData,
  arrayUnion,
} from "firebase/firestore";

import { db, app } from "../firebase.ts";

const AdminOrdersWithDelivery = () => {
  const [supplierId, setAuthTokens] = useState(
    localStorage.getItem("supplierId") || ""
  );

  const [data, setData] = useState<DocumentData[]>([]);
  const [hidden, setHidden] = useState("hidden");

  //Functions from backend
  const functions = getFunctions(app);
  const sendConfimation = httpsCallable(functions, "confirmOrder");

  //Modaler: Kanseller ordre
  const [showCancel, setShowCancel] = useState(false);
  const [idCanceledOrder, setIdCanceledOrder] = useState("");

  //Comment on order
  const [commentOrderID, setCommentOrderID] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState([]);
  const [newComment, setNewComment] = useState("");
  const handleChangeComment = (e: any) => {
    setNewComment(e.target.value);
  };

  //Intern comment on order
  const [commentInternOrderID, setCommentInternOrderID] = useState("");
  const [showCommentIntern, setShowCommentIntern] = useState(false);
  const [commentIntern, setCommentIntern] = useState("");
  const handleChangeCommentIntern = (e: any) => {
    setCommentIntern(e.target.value);
  };
  const [showFailed, setShowFailed] = useState(false);
  const handleCloseFailed = () => setShowFailed(false);
  const handleShowFailed = () => setShowFailed(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    let queryFetch = query(collection(db, "sales"));
    try {
      const doc = await getDocs(queryFetch);
      doc.forEach((doc) => {
        if (
          doc.data().paymentStatus === "paid" &&
          doc.data().delivery.method === "Levering"
        ) {
          if (doc.data().supplierId === supplierId) {
            list.push({ id: doc.id, ...doc.data() });
          }
        }
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  // Confirm order
  const [
    disableConfirmOrderButtonComponent,
    setDisableConfirmOrderButtonComponent,
  ] = useState(false);

  function sendConfirmationOnOrder(number: string, ordreId: string) {
    setDisableConfirmOrderButtonComponent(true);
    let phonenumber = number;
    let data = {
      phonenumber: phonenumber,
    };
    setHidden("");
    sendConfimation(data)
      .then(() => {
        updateStatusOrder(ordreId, "Bekreftet");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function updateStatusOrder(ordreId: string, status: string) {
    const docref = doc(db, "sales", ordreId);

    await updateDoc(docref, {
      status: status,
    }).then(() => {
      window.location.reload();
    });
  }

  async function changeCommentIntern(comment: string, orderId: string) {
    const updateObject = {
      [`commentSupplier`]: comment,
    };

    const docRef = doc(db, "sales", orderId);
    updateDoc(docRef, updateObject)
      .then((e) => {
        setShowCommentIntern(false);
      })
      .catch((e) => {
        handleShowFailed();
      });
  }

  async function addCommentToOrder(orderId: string) {
    let comments = {
      comment: newComment,
      date: new Date(),
      writer: "supplier",
    };
    const docRef = doc(db, "sales", orderId);

    await updateDoc(docRef, {
      commentCustomer: arrayUnion(comments),
    })
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error getting document:", e);
      });
  }

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  // Options form DateTime format
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="lg:px-20 px-0 lg:py-10 py-10 font-montserrat">
      <h1 className="lg:pb-4 text-4xl pl-3 lg:pl-20">
        Bestillinger med levering
      </h1>
      <Container>
        <Row className="justify-content-md-center">
          {data ? (
            <>
              {data.map((item) => {
                return (
                  <Col md={{ span: 5 }} className="g-4">
                    <AdminCard
                      date={capitalize(
                        new Date(
                          item.delivery.date.seconds * 1000
                        ).toLocaleString(`no-NO`, options)
                      )}
                      time={new Date(item.delivery.time.seconds*1000).getHours()}
                      menuId={item.menuName}
                      orderId={item.id.slice(0, 6)}
                      orderIdLink={item.id}
                      phoneNumber={item.phonenumber}
                      totalPrice={item.totalPrice}
                      status={item.status}
                      numPersons={item.peopleCount}
                      onClickCancelOrder={() => {
                        setIdCanceledOrder(item.id);
                        setShowCancel(true);
                      }}
                      disableConfirmOrderButton={
                        disableConfirmOrderButtonComponent
                      }
                      onClickConfirmOrder={() => {
                        sendConfirmationOnOrder(item.phonenumber, item.id);
                      }}
                      hidden={hidden}
                      visibilietyComment={
                        item.commentCustomer.length > 1 ? "" : "hidden"
                      }
                      visibilietyNoComment={
                        item.commentCustomer.length > 1 ? "hidden" : ""
                      }
                      onClickCommentInternal={() => {
                        if (item.commentSupplier) {
                          setCommentIntern(item.commentSupplier);
                        }
                        setCommentInternOrderID(item.id);
                        setShowCommentIntern(true);
                      }}
                      onClickComment={() => {
                        setComment([]);
                        if (item.commentCustomer.length > 0) {
                          const list: SetStateAction<DocumentData[]> = [];
                          item.commentCustomer.forEach((comment) => {
                            list.push(comment);
                          });
                          setComment(list);
                        }
                        setCommentOrderID(item.id);
                        setShowComment(true);
                      }}
                    />
                  </Col>
                );
              })}
            </>
          ) : (
            <div className="text-center">Ingen bestillinger</div>
          )}
        </Row>
      </Container>
      <ModalComponent
        show={showCancel}
        title="Er du sikker på at du vil kansellere ordren?"
        hide={() => setShowCancel(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={() => setShowCancel(false)}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Å kansellere en ordre kan ikke angres. Er du sikker på at du ønsker
            å kansellere orderen?
            <Row className="mt-2 mb-2">
              <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                <Button
                  variant="primary"
                  className="text-footer border-footer hover:border-white hover:text-white hover:bg-footer mt-3 w-3/12 ml-2"
                  onClick={() => console.log(idCanceledOrder)}
                >
                  Ja
                </Button>

                <Button
                  variant="secondary"
                  className="text-white bg-footer border-footer mt-3 w-3/12 ml-2"
                  onClick={() => setShowCancel(false)}
                >
                  Nei
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar internt på ordre*/}
      <ModalComponent
        show={showCommentIntern}
        title={`Internkommentar på ordre ${commentInternOrderID.slice(0, 6)}`}
        hide={() => setShowCommentIntern(false)}
        body={
          <>
            Se og endre kommentar på bestilling. <em>Ikke synlig for kunde.</em>
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.CommentIntern"
                onChange={handleChangeCommentIntern}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${commentIntern}`}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>
            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-[#24292e]"
                  onClick={() => {
                    changeCommentIntern(commentIntern, commentInternOrderID);
                  }}
                >
                  Legg til kommentar
                </Button>
                <Button
                  variant="secondary"
                  className="text-[#24292e] border-[#24292e] text-left ml-2"
                  onClick={() => setShowCommentIntern(false)}
                >
                  Lukk
                </Button>
              </Col>
            </Row>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComponent
        show={showFailed}
        hide={handleCloseFailed}
        body={
          <>
            <p>
              Kunne ikke oppdatere ønsket verdi akkurat nå. Vennligst prøv igjen
              senere, eller ta kontakt med teknisk ansvarlig.
            </p>
          </>
        }
      />
      {/* Modal for å legge inn kommentar på ordre*/}
      <ModalComponent
        show={showComment}
        title={`Kundechat på ordre ${commentOrderID.slice(0, 6)}`}
        hide={() => setShowComment(false)}
        body={
          <>
            {comment.length > 0 ? (
              <div>
                {comment.map((comment) => {
                  return (
                    <>
                      {comment.comment !== "" ? (
                        <>
                          {comment.writer === "supplier" ? (
                            <div className="w-8/12 float-right p-2 border-beige text-white rounded-xl mb-2 bg-beige">
                              {" "}
                              <div className=" float-left">
                                {comment.comment}
                              </div>
                              <div className="text-sm float-right">
                                {new Date(
                                  comment.date.seconds * 1000
                                ).toLocaleDateString(`no-NO`)}
                                ,{" "}
                                {new Date(
                                  comment.date.seconds * 1000
                                ).toLocaleTimeString(`no-NO`, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="p-2 border-beige border-2 text-beige rounded-xl mb-2 bg-white w-8/12 float-left">
                              {" "}
                              <div className=" float-left font-bold">
                                {comment.comment}
                              </div>
                              <div className="text-sm float-right">
                                {new Date(
                                  comment.date.seconds * 1000
                                ).toLocaleDateString(`no-NO`)}
                                ,{" "}
                                {new Date(
                                  comment.date.seconds * 1000
                                ).toLocaleTimeString(`no-NO`, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                  );
                })}
              </div>
            ) : null}
            <Form>
              <Form.Group
                className="mt-2 mb-1"
                controlId="form.Comment"
                onChange={handleChangeComment}
              >
                <Form.Control
                  className="font-medium tracking-widers text-black"
                  value={`${newComment}`}
                  placeholder="Ny melding"
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Form>

            <Row className="mt-2 mb-2 float-right">
              <Col>
                <Button
                  variant="secondary"
                  className="text-white bg-button border-button"
                  onClick={() => {
                    addCommentToOrder(commentOrderID);
                  }}
                >
                  Send melding
                </Button>
                <Button
                  variant="secondary"
                  className="text-button border-button text-left ml-2"
                  onClick={() => setShowComment(false)}
                >
                  Lukk
                </Button>
              </Col>
            </Row>
          </>
        }
      />
    </div>
  );
};

export default AdminOrdersWithDelivery;
