//@ts-nocheck

import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";

interface IFruity {
  id: number;
  fruit: string;
  prefix: string;
  suffix?: string;
}

const fruits: IFruity[] = [
  { id: 1, fruit: "Trondheim", prefix: "Du har valgt " }, //, suffix: "!"
  { id: 2, fruit: "Tønsberg", prefix: "Du har valgt " },
    { id: 3, fruit: "Stavanger", prefix: "Du har valgt " },
  //{ id: 3, fruit: "Oslo", prefix: "Du har valgt " },
  
];

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=""
      ref={ref}
      className="text-xl"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
      <span style={{ paddingLeft: "5px" }}>&#x25bc;</span>
    </a>
  )
);

type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

interface DropdownProps {
  onClick: () => void;
}

const DropdownSelector: FC<DropdownProps> = ({ onClick }) => {
  const [location, setLocation] = useState(
    localStorage.getItem("location") || "Trondheim"
  );

  const [selectedFruit, setSelectedFruit] = useState(0);

  const theChosenFruit = () => {
    const chosenFruit: IFruity = fruits.find((f) => f.id === selectedFruit);
    return chosenFruit
      ? chosenFruit.prefix + chosenFruit.fruit + (chosenFruit.suffix || "")
      : <a>Du har valgt {location}</a> ; //Hvilke by vil du bestille til?
  };

  return (
    <Dropdown onSelect={(e: string) => setSelectedFruit(Number(e))}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {theChosenFruit()}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {fruits.map((fruit) => {
          return (
            <Dropdown.Item
              key={fruit.id}
              eventKey={fruit.id.toString()}
              onClick={onClick}
            >
              {fruit.fruit}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSelector;
