import { FC } from "react";
import React from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";

//@ts-ignore
import "../style/footer.css";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return (
    <>
      <div className="bg-footer text-white main-footer -pb-10">
        <Row>
          <Col md={{ span: 3, offset: 1 }}>
            <div className="ml-6">
              <Col className="mt-8 mb-4">
                <div className="font-medium text-xl text-button tracking-wider font-bold mb-1">
                  Kasserolle.no
                </div>
                <div className="font-light text-lg">Catering, helt enkelt.</div>
              </Col>
              <div className="flex">
                <Nav.Item className="">
                  <Nav.Link
                    href="https://www.facebook.com/profile.php?id=100089446990684"
                    className="pb-2"
                  >
                    <FaFacebookSquare size={30} className="mr-2" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://www.facebook.com/profile.php?id=100089446990684"
                    className="pb-2"
                  >
                    <FaLinkedin size={30} />
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className=" sm:px-12 px-6 py-7">
              <h1 className="text-2xl pb-3">Om oss</h1>
              <div className="pb-2">Kasserolle AS</div>
              <div className="pb-2">Trondheim</div>
              <div className="pb-2">Org. nr. 922 712 220</div>
              <div className="pb-2">
                <a>kontakt@kasserolle.no</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="sm:px-12 px-4 py-7">
              <h1 className="text-2xl  pb-3">Hjelp</h1>
              <Nav.Item className="">
                <Nav.Link href="/finn-bestilling" className="pb-2">
                  Min bestilling
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/kontakt" className="pb-2">
                  Kontakt oss
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/betingelser" className="pb-2">
                  Betingelser
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/hvor-finner-du-oss" className="pb-2">
                  Her finner du oss
                </Nav.Link>
              </Nav.Item>
            </div>
          </Col>
          <Col md={3}>
            <div className="sm:px-12 px-4 py-7">
              <h1 className="text-2xl  pb-3">Logg inn</h1>
              <Nav.Item className="">
                <Nav.Link href="/logginn" className="pb-2">
                  Leverandør
                </Nav.Link>
              </Nav.Item>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
