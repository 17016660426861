import "firebase/database";
import { getFirestore } from "firebase/firestore";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbZpTeAJmb_zgQooiM9CkYqX7o2KTdC64",
  authDomain: "kasserollefirstapp.firebaseapp.com",
  projectId: "kasserollefirstapp",
  storageBucket: "kasserollefirstapp.appspot.com",
  messagingSenderId: "726458287742",
  appId: "1:726458287742:web:140be81492e53d182f7a2f",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize Firebase
export {db, app}

