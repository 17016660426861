//@ts-nocheck
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import NavBar from "./components/Navbar.tsx";
import Home from "./pages/Home.tsx";

import "bootstrap/dist/css/bootstrap.min.css";
import Menus from "./pages/Menus.tsx";
import MenuOrder from "./pages/MenuOrder.tsx";
import AboutUs from "./pages/AboutUs.tsx";
import LocationMaps from "./pages/LocationsMap.tsx";
import LoadingPage from "./pages/LoadingPage.tsx";
import OrderDetail from "./pages/OrderDetails.tsx";
import ContactUs from "./pages/ContactUs.tsx";
import Footer from "./components/Footer.tsx";
import Conditions from "./pages/Conditions.tsx";
import LogInCompany from "./pages/LogInCompany.tsx";
import Admin from "./pages/Admin.tsx";
import NavbarAdmin from "./components/NavbarAdmin.tsx";
import OfferCode from "./pages/OfferCode.tsx";
import ContactPersons from "./pages/ContactPersons.tsx";
import OrderComplete from "./pages/OrderComplete.tsx";
import LogIn from "./pages/LogIn.tsx";
import FindYourOrder from "./pages/FindYourOrder.tsx";
import CompanyPage from "./pages/CompanyPage.tsx";
import AdminOrdersWithDelivery from "./pages/AdminOrdersWithDelivery.tsx";
import Loading from "./pages/Loading.tsx";

function App() {
  let pathArray = window.location.pathname;
  return (
    <div className="page-container !bg-[#FDF9F3]">
      <div className="content-wrap">
        {pathArray.includes("admin") ? <NavbarAdmin /> : <NavBar />}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menyer" element={<Menus />} />
            <Route
              path="/menyer/:supplierId/:menuName"
              element={<MenuOrder />}
            />
            <Route path="/hvor-finner-du-oss" element={<LocationMaps />} />
            <Route path="/om-oss" element={<AboutUs />} />
            <Route path="/landingsside" element={<LoadingPage />} />
            <Route path="/loading/:orderId" element={<Loading />}/>
            <Route path="/kontakt" element={<ContactUs />} />
            <Route path="/order/:orderId" element={<OrderDetail />} />
            <Route path="/betingelser" element={<Conditions />} />
            <Route path="/logginn-" element={<LogIn />} />
            <Route path="/finn-bestilling" element={<FindYourOrder />} />
            <Route path="/logginn-bedrift" element={<LogInCompany />} />
            <Route
              path="/bedrift/9e1ae4d9-ccc9-4a02-9b4d82s"
              element={<CompanyPage />}
            />
            <Route
              path={`/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664`}
              element={<Admin />}
            />
            <Route
              path={`/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664/rabattkoder`}
              element={<OfferCode />}
            />
            <Route
              path={`/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664/kontaktpersoner`}
              element={<ContactPersons />}
            />
            <Route
              path={`/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664/leveringer`}
              element={<AdminOrdersWithDelivery />}
            />
            
            <Route path="/logginn" element={<LogIn />} />
            <Route
              path="/bestilt/c571b0b9-3834-4f30"
              element={<OrderComplete />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
