//@ts-nocheck
import { FC, useState } from "react";
import React from "react";
import { Card, Col, Form } from "react-bootstrap";

interface AditionalDishCardProps {
  img?: string;
  name?: string;
  max?: number;
  description?: string;
  numberOfInput?: string;
  price?: number;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AditionalDishCard: FC<AditionalDishCardProps> = ({
  img,
  name,
  description,
  price,
  onChange,
}) => {
  return (
    <div className="grid grid-rows-auto-fill">
      <Col className="my-8 mr-8 grid grid-rows-[330px,1fr] h-full">
        <Card className="h-full !bg-background !border-none">
          {img && img.trim() ? ( // Sjekker om `img` ikke er tom eller bare består av mellomrom
            <div className="relative aspect-w-16 aspect-h-9 overflow-hidden">
              <img
                src={img}
                alt=""
                className="!rounded-3xl object-cover absolute inset-0 w-full h-full"
              />
            </div>
          ) : null}

          <div className="overflow-auto py-2 font-medium tracking-wider min-h-[500px]">
            <h5 className="font-montserrat">{name}</h5>
            <h6 className="font-normal pt-2">{description}</h6>
            <h6 className="italic pt-2">{price},- per</h6>

            <Card className="text-black bg-white text-left lg:w-8/12 mt-3 shadow-sm">
              <Card.Body className="pt-2 pr-1 pl-1 pb-2 ">
                <input
                  type="number"
                  className="w-full pl-2"
                  min={1}
                  max={100}
                  onChange={onChange}
                  placeholder="Antall posjoner"
                />
              </Card.Body>
            </Card>
          </div>
        </Card>
      </Col>
    </div>
);
};

export default AditionalDishCard;
