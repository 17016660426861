//@ts-nocheck
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import om_oss from "../img/bilde_andreas.png";
import om_oss1 from "../img/bilde_oda.png";
import om_oss2 from "../img/bilde_profil1.png";
import om_oss3 from "../img/bilde_profil2.png";

import andreas_kontakt_oss from "../img/andreas_kontakt_oss.jpg";
import steven_kontakt_oss from "../img/steven_kontakt_oss.jpg";
import oda_kontakt_oss from "../img/oda_kontakt_oss.jpg";
import havard_kontakt_oss from "../img/havard_kontakt_oss.jpg";
import emil_kontakt_oss from "../img/emil_kontakt_oss.jpg";
import jonas_kontakt_oss from "../img/jonas_kontakt_oss.jpg";
import martin_kontakt_oss from "../img/martin_kontakt_oss.jpg";
import thor_kontakt_oss from "../img/thor_kontakt_oss.jpeg";
import logo from "../img/kasserolle_logo_rod.png";

const ContactUs = () => {
  return (
    <div>
      <Container>
        <Row xs={1} lg={2}>
          <Col className="lg:pt-20 pt-10" lg={5}>
            <h1 className="font-montserrat text-4xl"> Kontakt oss </h1>
            <div className="pt-4">
              Vi i Kasserolle AS setter stor pris på kundenes tilbakemeldinger
              og spørsmål. Vi står alltid til disposisjon for å hjelpe deg med å
              skreddersy din neste cateringopplevelse.
            </div>
            <div className="pt-4">
              Vennligst kontakt oss på e-post på{" "}
              <strong>kontakt@kasserolle.no</strong> eller på telefonnummer{" "}
              <strong>466 80 099</strong>, og vår kundeservice vil besvare ditt
              spørsmål så snart som mulig.{" "}
            </div>
            <div className="pt-4">Vi ser frem til å høre fra deg!</div>
          </Col>

          <Col className="pt-2 lg:pt-0 lg:pb-20 mb-10 lg:pl-20 lg:pr-20" lg={7}>
            <Row>
              <Col lg={{ offset: 0 }} className="g-4">
                <img
                  src={andreas_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="text-xl font-montserrat font-extrabold">
                    Andreas Torkildsen Hjertaker
                  </div>
                  <div className="pt-2 font-montserrat font-bold">
                    Daglig leder
                  </div>
                  <div className="pt-2 font-montserrat ">
                    andreas@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 414 96 901</div>
                </div>
              </Col>
              <Col lg={{ offset: 1 }} className="g-4">
                <img
                  src={havard_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="text-xl font-montserrat font-extrabold">
                    Håvard Hellang
                  </div>
                  <div className="pt-2 font-montserrat font-bold">
                    Prosjektkoordinator
                  </div>
                  <div className="pt-2 font-montserrat ">
                    haavard@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 466 80 099</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={{ offset: 0 }} className="g-4">
                <img
                  src={oda_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="text-xl font-montserrat font-extrabold">
                    Oda Steinland Skaug
                  </div>
                  <div className="pt-2 font-montserrat font-bold">
                    Teknisk leder
                  </div>
                  <div className="pt-2 font-montserrat ">oda@kasserolle.no</div>
                  <div className=" font-montserrat">+47 938 98 444</div>
                </div>
              </Col>
              <Col lg={{ offset: 1 }} className="g-4">
                <img
                  src={steven_kontakt_oss}
                  alt=""
                  className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
                />
                <div className="text-center">
                  <div className="text-xl font-montserrat font-extrabold">
                    Steven Xu
                  </div>
                  <div className="pt-2 font-montserrat font-bold">
                    Leverandøransvarlig
                  </div>
                  <div className="pt-2 font-montserrat ">
                    steven@kasserolle.no
                  </div>
                  <div className=" font-montserrat">+47 913 11 636</div>
                </div>
              </Col>
            </Row>
            <Row>
            <Col lg={{ offset: 0 }} className="g-4">
              <img
                src={jonas_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="text-xl font-montserrat font-extrabold">
                  Jonas Brigtsen Olsen
                </div>
                <div className="pt-2 font-montserrat font-bold">
                  Utvikler
                </div>
                <div className="pt-2 font-montserrat ">jonas@kasserolle.no</div>
                <div className=" font-montserrat">+47 980 57 752</div> 
              </div>
            </Col>
            <Col lg={{ offset: 1 }} className="g-4">
              <img
                src={emil_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="text-xl font-montserrat font-extrabold">
                  Emil Bjørlykke Berglund
                </div>
                <div className="pt-2 font-montserrat font-bold">
                  Utvikler
                </div>
                <div className="pt-2 font-montserrat ">
                  emil@kasserolle.no
                </div>
                <div className=" font-montserrat">+47 902 49 601</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ offset: 0 }} className="g-4">
              <img
                src={martin_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="text-xl font-montserrat font-extrabold">
                  Martin Kammen
                </div>
                <div className="pt-2 font-montserrat font-bold">
                  Forvaltning
                </div>
                <div className="pt-2 font-montserrat ">martin@kasserolle.no</div>
                <div className=" font-montserrat">+47 46819933</div>
              </div>
            </Col>
            <Col lg={{ offset: 1 }} className="g-4">
              <img
                src={thor_kontakt_oss}
                alt=""
                className=" pt-2 lg:ml-2 mb-3 rounded-3xl mx-auto"
              />
              <div className="text-center">
                <div className="text-xl font-montserrat font-extrabold">
                  Thor Holtung
                </div>
                <div className="pt-2 font-montserrat font-bold">
                  Strategi og Marked
                </div>
                <div className="pt-2 font-montserrat ">
                  thor@kasserolle.no
                </div>
                <div className=" font-montserrat">+47 97078176</div>
              </div>
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
