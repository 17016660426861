//@ts-nocheck
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { GiCookingPot } from "react-icons/gi";
import "../style/icons.css";

const Loading = () => {
    let ordreId = useParams();

    useEffect(() => {
        let id = ordreId.orderId
        setTimeout(function() {
            window.location.href = "/order/"+id;
        }, 5000)
    }, [])
  return (
    <>
      <div className="flex justify-center items-center ml-9 mt-20">
        <svg
          className="w-36 h-236 animate-bounce"
          fill="none"
          viewBox="0 0 24 24"
        >
          <GiCookingPot className="icons_loading" />
        </svg>
      </div>
    </>
  );
};

export default Loading;
