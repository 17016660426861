//@ts-nocheck
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  doc,
  getDoc,
  arrayUnion,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../firebase.ts";

import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ContactPersonComponent from "../components/ContactPersonComponent.tsx";

const ContactPersons = () => {
  const [supplierId, setAuthTokens] = useState(
    localStorage.getItem("supplierId") || ""
  );

  useEffect(() => {
    if (supplierId === "") {
      window.location.href = "/logginn";
    }
  }, [supplierId]);

  let data: (DocumentData | undefined)[] = [];
  const [contacts, setContacts] = useState();

  //Modaler
  const [nameContactperson, setNameContactperson] = useState("");
  const [emailContactperson, setEmailContactperson] = useState("");
  const [numberContactperson, setNumberContactperson] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (supplierId !== null) {
      let docRef = doc(db, "suppliers", supplierId);
      try {
        const doc = await getDoc(docRef);
        data.push(doc.data());
        setContacts(data[0].contacts);
      } catch (e) {
        console.log(e);
      }
    }
  };

  async function addContactperson() {
    let contact = {
      name: nameContactperson,
      phone: numberContactperson,
      email: emailContactperson,
      alertEmail: false,
      alertSms: false,
    };

    let contactpersonDocRef = doc(db, "suppliers", supplierId);
    await updateDoc(contactpersonDocRef, { contacts: arrayUnion(contact) })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function changeAlertsEmail(alertEmail: boolean, i: number) {
    let contactpersonDocRef = doc(db, "suppliers", supplierId);

    await getDoc(contactpersonDocRef).then((doc) => {
      var objects = doc.data().contacts;
      var objectToUpdate = objects[i];
      objectToUpdate.alertEmail = alertEmail;
      objects[i] = objectToUpdate;
      updateDoc(contactpersonDocRef, { contacts: objects });
    });
  }

  async function changeAlertsSms(alertSms: boolean, i: number) {
    let contactpersonDocRef = doc(db, "suppliers", supplierId);

    await getDoc(contactpersonDocRef).then((doc) => {
      var objects = doc.data().contacts;
      var objectToUpdate = objects[i];
      objectToUpdate.alertSms = alertSms;
      objects[i] = objectToUpdate;
      updateDoc(contactpersonDocRef, { contacts: objects });
    });
  }

  async function removeContactPerson(
    name: string,
    email: string,
    phone: string,
    alertEmail: boolean,
    alertSms: boolean
  ) {
    let contactpersonDocRef = doc(db, "suppliers", supplierId);
    await updateDoc(contactpersonDocRef, {
      contacts: arrayRemove({
        name: name,
        email: email,
        phone: phone,
        alertEmail: alertEmail,
        alertSms: alertSms,
      }),
    })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div>
      <Row className="justify-content-md-center md:w-12/12 pb-10">
        <Col md={{ span: 11, offset: 0 }} className="py-4 px-4">
          <div className="font-normal text-4xl tracking-wider lg:pb-4">
            {" "}
            Kontaktpersoner{" "}
          </div>
        </Col>
        <Col md={{ span: 7, offset: 0 }}>
          <div>
            <Container className="py-2 h-30">
              <Row className="pl-3">
                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold ml-2">Navn</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Mobil</div>
                </Col>
                <Col xs={2} md={4}>
                  <div className="text-md pt-2 font-bold">Epost</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold -ml-3">Varsling</div>
                </Col>
                <Col xs={3} md={1}>
                  <div className="text-md pt-2 font-bold float-right mr-4">
                    Slett
                  </div>
                </Col>
              </Row>
              {contacts ? (
                <div>
                  {contacts.length >= 1 ? (
                    <>
                      {" "}
                      {contacts.map((doc, i) => (
                        <ContactPersonComponent
                          name={doc.name}
                          phone={doc.phone}
                          email={doc.email}
                          alertEmail={doc.alertEmail}
                          alertSms={doc.alertSms}
                          onClickDelete={() => {
                            removeContactPerson(
                              doc.name,
                              doc.email,
                              doc.phone,
                              doc.alertEmail,
                              doc.alertSms
                            );
                          }}
                          onClickChangeEmail={(e) => {
                            changeAlertsEmail(e, i);
                          }}
                          onClickChangeSms={(e) => {
                            changeAlertsSms(e, i);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <ContactPersonComponent
                      name={contacts.name}
                      phone={contacts.phone}
                      email={contacts.email}
                      alertEmail={contacts.alertEmail}
                      alertSms={contacts.alertSms}
                      onClickDelete={() => {
                        removeContactPerson(
                          contacts.name,
                          contacts.email,
                          contacts.phone,
                          contacts.alertEmail,
                          contacts.alertSms
                        );
                      }}
                      onClickChangeEmail={(e) => {
                        changeAlertsEmail(e);
                      }}
                      onClickChangeSms={(e) => {
                        changeAlertsSms(e);
                      }}
                    />
                  )}{" "}
                </div>
              ) : null}
            </Container>
          </div>
        </Col>
        <Col md={{ span: 4 }}>
          <div>
            <Container className="py-2 h-30 ">
              <Row className="pl-3">
                <div className="text-2xl pt-2 font-medium">
                  Legg til kontaktperson
                </div>
                <Row>
                  <Col>
                    <div className=" mt-3">
                      <Card className="w-10/12">
                        <Card.Body>
                          <div className="text-xs text-light pb-2 text-black">
                            NAVN{" "}
                          </div>
                          <input
                            value={nameContactperson}
                            onChange={(e) =>
                              setNameContactperson(e.target.value)
                            }
                            placeholder="Navn på kontaktperson"
                            className="w-64"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col>
                    <div className=" mt-3">
                      <Card className="w-10/12">
                        <Card.Body>
                          <div className="text-xs text-light pb-2 text-black">
                            E-POST{" "}
                          </div>
                          <input
                            value={emailContactperson}
                            onChange={(e) =>
                              setEmailContactperson(e.target.value)
                            }
                            placeholder="E-post til kontaktperson"
                            className="w-64"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <div className=" mt-3">
                    <Card className="w-10/12">
                      <Card.Body>
                        <div className="text-xs text-light pb-2 text-black">
                          TELEFONNUMMER{" "}
                        </div>
                        <input
                          value={numberContactperson}
                          className="w-64"
                          onChange={(e) =>
                            setNumberContactperson(e.target.value)
                          }
                          placeholder="Telefonnummer til kontaktperson"
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant="secondary"
                    className="bg-[#e57e22] text-white h-12 rounded-md ml-3 w-6/12 border-[#e57e22] hover:bg-[#FFA554] hover:border-[#FFA554] focus:bg-[#e57e22] focus:border-[#e57e22] active:bg-[#FFA554] active:border-[#e57e22] mt-3"
                    onClick={() => addContactperson()}
                  >
                    Opprett
                  </Button>
                  <Row />
                </Row>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactPersons;
