//@ts-nocheck
import React from "react";
import MenuCard from "../components/MenuCard.tsx";
import { useNavigate } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";

import { GiCookingPot } from "react-icons/gi";

import tapas_example from "../img/tapas_eksempel.jpeg";
import { useState } from "react";
import { useEffect } from "react";

import {
  collection,
  DocumentData,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase.ts";
import { idText } from "typescript";
import Meny from "../components/CustomToggle.js";
import DropdownSelector from "../components/CustomDropdown.tsx";

const Menus = () => {
  const [location, setLocation] = useState(
    localStorage.getItem("location") || "Trondheim"
  );

  const [menuData, setMenuData] = useState();
  const [supplierData, setSupplierData] = useState();
  const [city, setCity] = useState(location);

  let navigation = useNavigate();

  const routeChange = (path: string) => {
    navigation(path);
  };

  async function fetchSuppliers() {
    const list: SetStateAction<DocumentData[]> = [];
    const queryRef = query(collection(db, "suppliers"));
    try {
      const docs = await getDocs(queryRef);
      docs.forEach((doc) => {
        let data = doc.data();
        if (data.menus) {
          list.push(...data.menus);
        }
      });
      setMenuData([...list]);
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  async function fetchSuppliersBasedOnCity() {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "suppliers"), where("city", "==", city));
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setSupplierData([...list]);
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  useEffect(() => {
    if (city !== "") {
      fetchSuppliersBasedOnCity();
      fetchSuppliers();
    }
  }, [city]);

  return (
    <Container>
      <div className="lg:px-10 py-10 font-montserrat">
        <h1 className="lg:pb-6 text-4xl">Våre menyer</h1>
        <div>
          <DropdownSelector
            onClick={(e) => setCity(e.currentTarget.textContent)}
          />{" "}
        </div>
        {city !== "" ? (
          <div>
            {supplierData ? (
              <>
                {supplierData.map((supplier) => {
                  let id = supplier.id;
                  return (
                    <>
                      <div>
                        <Row>
                          {supplier.menus.map((menu) => {
                            return (
                              <Col
                                md={{ span: 4 }}
                                sm={{ span: 6 }}
                                xs={{ span: 12 }}
                                className="pb-4 g-4"
                              >
                                <MenuCard
                                  img={menu.img}
                                  title={menu.name}
                                  description={menu.description}
                                  price={menu.price}
                                  onClick={() =>
                                    routeChange("/menyer/" + id + "/" + menu.id)
                                  }
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <div className="flex justify-center items-center ml-9 mt-20">
                <svg
                  className="w-36 h-236 animate-bounce"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <GiCookingPot className="icons_loading" />
                </svg>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default Menus;
