//@ts-nocheck

import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ImLocation } from "react-icons/im";

import "../style/toggle.css";

interface ICity {
  id: number;
  city: string;
  prefix: string;
  suffix?: string;
}

const citys: ICity[] = [
  { id: 1, city: "Trondheim" },
  { id: 2, city: "Tønsberg" },
  { id: 3, city: "Stavanger" },
  //{ id: 3, city: "Oslo" },
];

type ToggleSimpleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const ToggleSimple = React.forwardRef(
  (props: ToggleSimpleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=""
      ref={ref}
      className="text-sm pr-6 text-white flex lg:pl-0 pl-2 font-montserrat tracking-wide"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      <div className="pr-1 pt-0.5">
        {" "}
        <ImLocation size={16} />{" "}
      </div>

      {props.children}
      {/*       <span style={{ paddingLeft: "5px", fontSize: "0.8em", color: "white" }}>
        &#x25bc;
      </span> */}
    </a>
  )
);

type CustomMenuProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  labeledBy?: string;
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
      >
        <ul className="!text-white">
          {React.Children.toArray(props.children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

interface DropdownProps {
  onClick: () => void;
}

const DropdownSelector: FC<DropdownProps> = ({ onClick }) => {
  const [location, setLocation] = useState(
    localStorage.getItem("location") || "Trondheim"
  );

  const [selectedCity, setSelectedCity] = useState(0);

  const theChosenCity = () => {
    const chosenCity: ICity = citys.find((c) => c.id === selectedCity);

    return chosenCity ? (
      chosenCity.city + (chosenCity.suffix || "")
    ) : (
      <a>{location}</a>
    ); //Hvilke by vil du bestille til?
  };

  return (
    <Dropdown onSelect={(e: string) => setSelectedCity(Number(e))}>
      <Dropdown.Toggle as={ToggleSimple} id="dropdown-custom-components">
        {theChosenCity()}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        id="customToggleSimple"
        className="simpleToggle"
      >
        {citys.map((city) => {
          return (
            <Dropdown.Item
              key={city.id}
              eventKey={city.id.toString()}
              onClick={onClick}
            >
              {city.city}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSelector;
