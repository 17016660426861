//@ts-nocheck
import { Row, Col, Nav, Navbar, NavLink } from "react-bootstrap";
import React from "react";
import logo from "../img/kasserolle_logo_rod.png";
import { IoNotificationsOutline, IoNotificationsSharp } from "react-icons/io5";
import { useState } from "react";
import { useEffect } from "react";
import { doc, getDoc, query, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase.ts";

const orders = [
  {
    id: 1,
    name: "Kasserolle 1",
    date: "2021-10-10",
    time: "12:00",
    status: "Ikke bekreftet",
  },
  {
    id: 1,
    name: "Kasserolle 1",
    date: "2021-10-10",
    time: "12:00",
    status: "Ikke bekreftet",
  },
  {
    id: 1,
    name: "Kasserolle 1",
    date: "2021-10-10",
    time: "12:00",
    status: "Bekreftet",
  },
];

const NavbarAdmin = () => {
  const [notification, setNotification] = useState(false);
  const [supplierId, setSupplierId] = useState(
    localStorage.getItem("supplierId") || ""
  );
  const [supplierName, setSupplierName] = useState("");

  async function fetchSupplier() {
    let docRef = doc(db, "suppliers", supplierId);
    try {
      const doc = await getDoc(docRef);
      if (doc.exists()) {
        let data = doc.data();
        setSupplierName(data.name);
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  const fetchOrders = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    let queryFetch = query(collection(db, "sales"));
    try {
      const doc = await getDocs(queryFetch);
      doc.forEach((doc) => {
        if (doc.data().paymentStatus === "paid") {
          if (doc.data().supplierId === supplierId) {
            if (doc.data().status === "Ikke bekreftet") {
              setNotification(true);
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (supplierId !== "") {
      fetchOrders();
    }
  });

  useEffect(() => {
    fetchSupplier();
  }, []);
  
  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      bg="#FDF9F3"
      variant="light"
      className="w-100 gap-3 px-4"
    >
      <Navbar.Brand href="/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664">
        {window.innerWidth < 960 ? (
          <div>
            <Row>
              <Col>
                <img
                  alt="logo_mobil"
                  className="h-16 -mt-1 -mb-3 -ml-2 w-20 pr-1"
                  src={logo}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col>
                <img alt="logo_mobil" className="w-20 ml-6 mt-2" src={logo} />
              </Col>
              <Col className="g-0">
                <div className="font-montserrat text-2xl mt-10 ml-2">
                  {supplierName}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto justify-content-end flex-grow-1  gap-3 px-3">
          <Nav.Link
            href={"/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664"}
            className="mt-2"
          >
            Bestillinger
          </Nav.Link>
          <Nav.Link
            href={"/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664/kontaktpersoner"}
            className="mt-2"
          >
            Kontaktpersoner
          </Nav.Link>
          <Nav.Link
            href={"/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664/rabattkoder"}
            className="mt-2"
          >
            Rabattkoder
          </Nav.Link>
          {notification ? (
            <div>
              <IoNotificationsSharp
                size={22}
                className="mt-3"
                color="#DA271B"
              />{" "}
            </div>
          ) : (
            <div>
              <IoNotificationsOutline size={22} className="mt-3" />{" "}
            </div>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarAdmin;
