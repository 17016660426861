// @ts-nocheck
import {
  collection,
  DocumentData,
  getDocs,
  query,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";
import { SetStateAction, useEffect, useState } from "react";
import { Col, Container, Row, Card, Button } from "react-bootstrap";
// @ts-ignore
import ModalComponent from "../components/ModalComponent.tsx";
// @ts-ignore
import OfferCodeComponent from "../components/OfferCodeComponent.tsx";

// @ts-ignore
import { db, app } from "../firebase.ts";

const OfferCode = () => {
  const [supplierId, setAuthTokens] = useState(
    localStorage.getItem("supplierId") || ""
  );

  useEffect(() => {
    if (supplierId === "") {
      window.location.href = "/logginn";
    }
  }, [supplierId]);

  const [data, setData] = useState<DocumentData[]>([]);
  //Data for opprett rabattkode
  const [sendIdRabattkode, setSendIdRabattkode] = useState("");
  const [sendValueRabattkode, setSendValueRabattkode] = useState("");
  const [sendCountRabattkode, setSendCountRabattkode] = useState("");

  //Data for sende rabattkode
  const [phonenumberSend, setPhonenumberSend] = useState("");
  const [rabattkodeSend, setRabattkodeSend] = useState("");

  //Data for å redigere rabattkode
  const [idEditRabattkode, setIdEditRabattkode] = useState("");
  const [valueEditRabattkode, setValueEditRabattkode] = useState("");
  const [countEditRabattkode, setCountEditRabattkode] = useState("");

  //Data for å slette rabattkode
  const [idDeleteRabattkode, setIdDeleteRabattkode] = useState("");

  const handleEditRabattkode = () => setShowEditRabattkode(false);
  const handleDeleteRabattkode = () => setShowDeleteRabattkode(false);

  //Håndterer modaler
  const [showDeleteRabattkode, setShowDeleteRabattkode] = useState(false);
  const [showEditRabattkode, setShowEditRabattkode] = useState(false);

  const [showRabattkodeSent, setShowRabattkodeSent] = useState(false);
  const handleRabattkodeSent = () => setShowRabattkodeSent(false);

  const [showRabattkodeNotSent, setShowRabattkodeNotSent] = useState(false);
  const handleRabattkodeNotSent = () => setShowRabattkodeNotSent(false);

  const functions = getFunctions(app);
  const sendRabattkodeFunksjon = httpsCallable(functions, "sendDiscount");

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "discount"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function deleteRabattkode() {
    await deleteDoc(doc(db, "discount", idDeleteRabattkode))
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function editRabattkode() {
    const rabattkodeUpdate = doc(db, "discount", idEditRabattkode);
    updateDoc(rabattkodeUpdate, {
      valid: countEditRabattkode,
      value: valueEditRabattkode,
    })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function addRabattkode() {
    await setDoc(doc(db, "discount", sendIdRabattkode), {
      valid: sendCountRabattkode,
      value: sendValueRabattkode,
    })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function sendRabattkodeToNumber() {
    data.map((e) => {
      if (e.id === rabattkodeSend) {
        let sendData = {
          tlf: phonenumberSend,
          code: rabattkodeSend,
          value: e.value,
        };
        sendRabattkodeFunksjon(sendData)
          .then((response) => {
            setPhonenumberSend("");
            setRabattkodeSend("");
            setShowRabattkodeSent(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setPhonenumberSend("");
        setRabattkodeSend("");
        setShowRabattkodeNotSent(true);
      }
    });
  }

  return (
    <>
      {/* Modal når rabattkode blir sendt*/}
      <ModalComponent
        show={showRabattkodeSent}
        title="Rabattkode ble sendt!"
        hide={handleRabattkodeSent}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleRabattkodeSent}
          >
            Lukk
          </Button>
        }
      />
      {/* Modal når rabattkode IKKE blir sendt*/}
      <ModalComponent
        show={showRabattkodeNotSent}
        title="Rabattkode ble ikke sendt"
        hide={handleRabattkodeNotSent}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleRabattkodeNotSent}
          >
            Lukk
          </Button>
        }
        body={<div>Rabattkode eksisterer ikke og ble derfor ikke sendt.</div>}
      />
      {/* Modal for å redigere rabattkode*/}
      <ModalComponent
        show={showEditRabattkode}
        title="Rediger rabattkode"
        hide={handleEditRabattkode}
        footer={
          <>
            <Button
              variant="secondary"
              className="text-white bg-[#e57e22] border-[#e57e22] text-left ml-16"
              onClick={(e) => editRabattkode()}
            >
              Oppdater rabattkode
            </Button>
            <Button
              variant="secondary"
              className="text-white bg-[#24292e] text-left"
              onClick={handleEditRabattkode}
            >
              Lukk
            </Button>
          </>
        }
        body={
          <div>
            <Row>
              <Col>
                <div className="mt-3">
                  <Card>
                    <Card.Body>
                      <div className="text-xs text-light pb-2 text-black">
                        RABATTKODE{" "}
                      </div>
                      <input
                        value={`${idEditRabattkode}`}
                        onChange={(e) => setIdEditRabattkode(e.target.value)}
                        className="w-100"
                      />
                    </Card.Body>
                  </Card>
                </div>

                <div className="mt-3">
                  <Card>
                    <Card.Body>
                      <div className="text-xs text-light pb-2 text-black">
                        VERDI{" "}
                      </div>
                      <input
                        value={`${valueEditRabattkode}`}
                        onChange={(e) => setValueEditRabattkode(e.target.value)}
                        className="w-100"
                      />
                    </Card.Body>
                  </Card>
                </div>

                <div className=" mt-3">
                  <Card>
                    <Card.Body>
                      <div className="text-xs text-light pb-2 text-black">
                        ANTALL GYLDIGE{" "}
                      </div>
                      <input
                        value={`${countEditRabattkode}`}
                        onChange={(e) => setCountEditRabattkode(e.target.value)}
                        className="w-100"
                      />
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        }
      />
      {/* Modal for å slette rabattkode*/}
      <ModalComponent
        show={showDeleteRabattkode}
        title="Slett rabattkode"
        hide={handleDeleteRabattkode}
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16"
            onClick={handleDeleteRabattkode}
          >
            Lukk
          </Button>
        }
        body={
          <div>
            <>
              Ønsker du å slette rabattkoden: {idDeleteRabattkode}?
              <Row className="mt-2 mb-2">
                <Col lg={{ span: 11, offset: 3 }} xs={{ offset: 2, span: 12 }}>
                  <Button
                    variant="primary"
                    className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12 ml-2"
                    onClick={() => deleteRabattkode()}
                  >
                    Ja
                  </Button>

                  <Button
                    variant="secondary"
                    className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                    onClick={handleDeleteRabattkode}
                  >
                    Nei
                  </Button>
                </Col>
              </Row>
            </>
          </div>
        }
      />
      <Row className="justify-content-md-center mt-2 md:w-11/12 pb-10">
        <Col md={{ span: 11, offset: 2 }} className=" px-4">
          <div className="font-normal text-4xl tracking-wider lg:pb-4">
            {" "}
            Rabattkoder{" "}
          </div>
        </Col>
        <Col md={{ span: 6, offset: 1 }}>
          <div>
            <Container className="py-2 h-30 lg:px-14">
              <Row className="pl-3">
                <Col xs={4} md={4}>
                  <div className="text-md pt-2 font-bold ml-2">Rabattkode</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="text-md pt-2 font-bold">Verdi</div>
                </Col>
                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold">Antall gyldige</div>
                </Col>

                <Col xs={3} md={3}>
                  <div className="text-md pt-2 font-bold float-right mr-4">
                    Rediger
                  </div>
                </Col>
              </Row>
              {data.map((doc) => (
                <OfferCodeComponent
                  rabattkode={doc.id}
                  count={doc.valid}
                  value={doc.value}
                  onClickEdit={() => {
                    setIdEditRabattkode(doc.id);
                    setCountEditRabattkode(doc.valid);
                    setValueEditRabattkode(doc.value);
                    setShowEditRabattkode(true);
                  }}
                  onClickDelete={() => {
                    setIdDeleteRabattkode(doc.id);
                    setShowDeleteRabattkode(true);
                  }}
                />
              ))}
            </Container>
          </div>
        </Col>
        <Col md={{ span: 5 }}>
          <div>
            <Container className="py-2 h-30 lg:px-14">
              <Row className="pl-3">
                <div className="text-2xl pt-2 font-medium">
                  Opprett rabattkode
                </div>
                <Row>
                  <Col>
                    <div className=" mt-3">
                      <Card className="w-10/12">
                        <Card.Body>
                          <div className="text-xs text-light pb-2 text-black">
                            RABATTKODE{" "}
                          </div>
                          <input
                            value={sendIdRabattkode}
                            onChange={(e) =>
                              setSendIdRabattkode(e.target.value)
                            }
                            placeholder="Navn på rabattkode"
                            className="w-64"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col>
                    <div className=" mt-3">
                      <Card className="w-10/12">
                        <Card.Body>
                          <div className="text-xs text-light pb-2 text-black">
                            VERDI{" "}
                          </div>
                          <input
                            value={sendValueRabattkode}
                            onChange={(e) =>
                              setSendValueRabattkode(e.target.value)
                            }
                            placeholder="Verdi (0-1 for prosent)"
                            className="w-64"
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <div className=" mt-3">
                    <Card className="w-10/12">
                      <Card.Body>
                        <div className="text-xs text-light pb-2 text-black">
                          ANTALL GYLDIGE{" "}
                        </div>
                        <input
                          value={sendCountRabattkode}
                          className="w-64"
                          onChange={(e) =>
                            setSendCountRabattkode(e.target.value)
                          }
                          placeholder="Kan brukes X ganger"
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant="secondary"
                    className="bg-[#e57e22] text-white h-12 rounded-md ml-3 w-6/12 border-[#e57e22] hover:bg-[#FFA554] hover:border-[#FFA554] focus:bg-[#e57e22] focus:border-[#e57e22] active:bg-[#FFA554] active:border-[#e57e22] mt-3"
                    onClick={addRabattkode}
                  >
                    Opprett
                  </Button>
                  <Row />
                </Row>

                <div className="text-2xl pt-4 font-medium">
                  Send eksisterende rabattkode
                </div>
                <Row>
                  <div className=" mt-3">
                    <Card className="w-10/12">
                      <Card.Body>
                        <div className="text-xs text-light pb-2 text-black">
                          RABATTKODE{" "}
                        </div>
                        <input
                          className="w-64"
                          placeholder="Rabattkode du vil sende"
                          value={rabattkodeSend}
                          onChange={(e) => setRabattkodeSend(e.target.value)}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <div className=" mt-3">
                    <Card className="w-10/12">
                      <Card.Body>
                        <div className="text-xs text-light pb-2 text-black">
                          TELEFONNUMMER{" "}
                        </div>
                        <input
                          value={phonenumberSend}
                          onChange={(e) => setPhonenumberSend(e.target.value)}
                          className="w-64"
                          placeholder="Telefonnummer du vil sende til"
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant="secondary"
                    className="bg-[#e57e22] text-white h-12 rounded-md ml-3 w-6/12 border-[#e57e22] hover:bg-[#FFA554] hover:border-[#FFA554] focus:bg-[#e57e22] focus:border-[#e57e22] active:bg-[#FFA554] active:border-[#e57e22] mt-3"
                    onClick={(e) => sendRabattkodeToNumber()}
                  >
                    Send
                  </Button>
                  <Row />
                </Row>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OfferCode;
