import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Conditions = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-md-center mt-10 md:w-11/12 pb-10">
          <Col lg={{ span: 7, offset: 1 }}>
            <h1 className="lg:py-8 py-10 font-montserrat text-4xl">
              Betingelser
            </h1>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4">
                A) BESTILLING
              </div>
              Alle bestillinger skal gjøres gjennom vår digitale
              distribusjonskanal. En bestilling er bindende når den er bekreftet
              av Kasserolle AS.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                B) PRISER
              </div>
              Prisene som er oppgitt på vår nettside eller i vår digitale
              distribusjonskanal inkluderer MVA. Kasserolle AS forbeholder seg
              retten til å endre priser uten forvarsel.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                C) LEVERING
              </div>
              Levering av maten vil skje gjennom eksterne leverandører.
              Leveringstiden kan variere avhengig av leveringsstedet og
              bestillingen. Kasserolle AS vil gjøre sitt beste for å overholde
              avtalt leveringstid, men kan ikke holdes ansvarlig for
              forsinkelser eller mangler som skyldes eksterne leverandører eller
              andre uforutsette omstendigheter.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                D) BETALING
              </div>
              Betaling skal skje gjennom vår digitale distribusjonskanal.
              Kasserolle AS aksepterer kredittkort, debetkort, Vipps og faktura
              (for bedrifter) som betalingsmetoder. Betaling eller forpliktelse
              til å betale faktura vil bli gjort i sin helhet ved bestilling.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                E) ENDRINGER
              </div>
              Endring eller avbestilling skal skje skriftlig, eller registreres
              gjennom bestillingssiden på Kasserolle.no, og må være mottatt av
              Kasserolle AS minst 72 timer før avtalt leveringstid. Endring
              eller avbestilling som skjer senere enn 72 timer før avtalt
              leveringstid vil bli belastet full pris.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                F) RETT TIL Å AVSLÅ BESTILLING
              </div>
              Kasserolle AS forbeholder seg retten til å avslå enhver bestilling
              uten å oppgi grunn.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                G) ANSVAR
              </div>
              Kasserolle AS er ikke ansvarlig for eventuelle skader eller tap
              som oppstår under levering av maten, eller for eventuelle
              allergier eller matintoleranser som kunden kan ha. Kunden er
              ansvarlig for å gi Kasserolle AS beskjed om eventuelle allergier
              eller matintoleranser ved bestilling.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                H) PERSONVERN
              </div>
              Kasserolle AS vil behandle personopplysningene dine i henhold til
              gjeldende lovgivning om personvern. Personvern er et viktig tema
              for Kasserolle AS, og vi forplikter oss til å beskytte
              personopplysningene dine i henhold til gjeldende lovgivning om
              personvern. Vi samler inn, bruker og oppbevarer kun
              personopplysningene dine i den grad det er nødvendig for å kunne
              tilby deg våre tjenester og for å oppfylle våre forpliktelser i
              henhold til kjøpsbetingelsene, og levere gode kundeopplevelser.
              Når det gjelder markedsføring, vil vi kun sende deg e-post eller
              annen kommunikasjon dersom du har gitt oss samtykke til det. Du
              kan til enhver tid melde deg av vårt e-postregister eller avslutte
              abonnementet på annen kommersiell kommunikasjon ved å følge
              instruksjonene i e-posten eller kommunikasjonen du mottar.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                I) GARANTI OG REKLAMASJON
              </div>
              Kasserolle AS garanterer at maten som leveres er av god kvalitet
              og er frisk. Dersom det oppstår et problem med maten, vennligst
              kontakt oss umiddelbart for å løse problemet. Reklamasjoner må
              være skriftlige og må være mottatt av Kasserolle AS innen 48 timer
              etter levering. Reklamasjoner må inneholde komplett informasjon om
              omstendighetene samt eventuell dokumentasjon. Den må sendes på
              mail. Kjøper skal melde fra om feil og mangel så snart de
              oppdages. Retten til å reklamasjon kan falle bort ved mistanke om
              mangelen er forårsaket av kjøper. Derfor kan det være for sent å
              melde fra over en time etter mottak av mat, eller dersom maten er
              spist opp.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                J) AVBESTILLING OG ENDRINGER
              </div>
              Kjøper har rett på full refusjon dersom bestillingen avbestilles
              innen 7 dager før levering. Kjøper plikter ikke å hente bestilt
              mat, og kan avbestille uten refusjon når som helst. Angrefristen
              anses overholdt dersom melding er sendt før utløpet av fristen.
              Kjøper har bevisbyrden for at angreretten er blitt gjort
              gjeldende, og meldingen bør derfor skje skriftlig. Kasserolle AS
              behandler endringer (reduksjon eller økning) av bestilling
              digitalt, og endringer er bindende etter bekreftelse.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                K) TVISTER
              </div>
              Eventuelle tvister som oppstår mellom Kasserolle AS og kunden skal
              løses i henhold til norsk lovgivning.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                L) OPPHAVSRETT
              </div>
              Opphavsretten til alt materiale, inkludert bilder og tekst, som
              finnes på vår nettside eller i vår digitale distribusjonskanal
              tilhører Kasserolle AS eller våre leverandører. Det er ikke
              tillatt å bruke, reprodusere eller distribuere dette materialet
              uten skriftlig samtykke fra Kasserolle AS.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                M) SPESIELLE KRAV TIL ALLERGENER OG MATINTOLERANSER
              </div>
              Kunden er ansvarlig for å gi Kasserolle AS beskjed om eventuelle
              allergier eller matintoleranser ved bestilling. Kasserolle AS vil
              gjøre sitt beste for å imøtekomme disse kravene, men kan ikke
              holdes ansvarlig for eventuelle allergiske reaksjoner eller andre
              problemer som kan oppstå som følge av matintoleranser.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                N) SPESIELLE KRAV TIL BESTILLING OG BETALING FOR STØRRE
                ARRANGEMENTER
              </div>
              For bestillinger av catering til større arrangementer, kan
              Kasserolle AS kreve forhåndsbetaling eller et minimumsbeløp for
              bestilling. Dette vil bli avtalt med kunden på forhånd.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                O) SPESIELLE KRAV TIL AVBESTILLING FOR STØRRE ARRANGEMENTER
              </div>
              For bestillinger av catering til større arrangementer kan
              Kasserolle AS kreve at avbestilling må skje senest en viss dato
              før arrangementet, og dette vil bli avtalt med kunden på forhånd.
              Avbestillinger som skjer senere enn denne datoen vil bli belastet
              full pris.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                P) ANSVAR FOR RETUR AV UTSTYR
              </div>
              Kunden er ansvarlig for å returnere eventuelt utstyr som er levert
              av Kasserolle AS i samme stand som det ble mottatt. Kasserolle AS
              forbeholder seg retten til å belaste kunden for skader på eller
              tap av utstyr.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                Q) ANSVAR FOR SKADER PÅ UTSTYR
              </div>
              Kasserolle AS er ikke ansvarlig for skader på utstyr som er levert
              til kunden som følge av uaktsomhet eller misbruk fra kundens side.
              Kunden vil bli belastet for skader på eller tap av utstyr.
            </div>
            <div>
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                R) TILGANG TIL LOKALER
              </div>
              Kunden er ansvarlig for å sørge for at Kasserolle AS har tilgang
              til lokalene som er nødvendige for å utføre sine tjenester.
              Kasserolle AS forbeholder seg retten til å avbryte tjenestene
              dersom det ikke er mulig å få tilgang til lokalene.
            </div>
            <div className="pb-4">
              <div className="font-normal text-xl tracking-wider pb-4 pt-6">
                S) ANSVAR FOR SKADE PÅ LOKALER
              </div>
              Kasserolle AS er ikke ansvarlig for skade på lokaler som følge av
              deres tjenester, med mindre skaden skyldes uaktsomhet eller
              forsømmelig oppførsel fra Kasserolle AS side. Kunden vil bli
              belastet for skader på lokaler som er forårsaket av deres
              handlinger eller unnlatelse.
            </div>
            <hr className="-pl-4"/>
            <div>
              <div className="pt-4">
               
                Kasserolle AS forbeholder seg retten til å endre disse
                kjøpsbetingelsene uten forvarsel. Endringene vil tre i kraft fra
                det tidspunktet de blir publisert på vår nettside eller i våre
                digitale distribusjonskanaler.
              </div>
              <div className="pt-6">
                Dersom du har spørsmål eller kommentarer vedrørende våre
                kjøpsbetingelser eller din bestilling, vennligst kontakt oss på
                kontakt@kasserolle.no eller via Kasserolle.no.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Conditions;
