import { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillTelephoneFill, BsPeopleFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa";
import { GrStatusUnknown } from "react-icons/gr";
import { GrStatusGood } from "react-icons/gr";
import { GrStatusDisabled } from "react-icons/gr";
import { FaRegComment } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { GrRestaurant } from "react-icons/gr";

import "../style/icons.css";

import React from "react";
import { GiCookingPot, GiNotebook } from "react-icons/gi";

interface AdminCardProps {
  date?: any;
  time?: any;
  menuId?: string;
  orderId?: any;
  phoneNumber?: number;
  totalPrice?: number;
  deliveryMethod?: string;
  disableConfirmOrderButton?: boolean;
  hidden?: string;
  animateSpinner?: string;
  restaurant?: string;
  visibilietyComment?: string;
  visibilietyNoComment?: string;
  numPersons?: number;
  orderIdLink?: string;
  status?: string;
  onClickCancelOrder?: () => void;
  onClickConfirmOrder?: () => void;
  onClickComment?: () => void;
  onClickCommentInternal?: () => void;
}

const AdminCard: FC<AdminCardProps> = ({
  date,
  time,
  menuId = "",
  orderId,
  numPersons,
  orderIdLink,
  phoneNumber,
  totalPrice,
  disableConfirmOrderButton,
  status = "Ikke bekreftet",
  visibilietyComment,
  visibilietyNoComment,
  hidden,
  onClickCancelOrder,
  onClickConfirmOrder,
  onClickComment,
  onClickCommentInternal,
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  return (
    <div className="tracking-wide font-light ">
      <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigateToOtherSite(`/order/${orderIdLink}`)}
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {date}, klokken {time}
              </div>
              <div>
                <strong className="font-medium">{menuId}</strong> - {orderId}
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phoneNumber}
                    </strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div className="float-right">
                    <MdAttachMoney size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {totalPrice},-
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              {/* <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div>
                    <GrRestaurant size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {restaurant}
                    </strong>
                  </div>
                </div>
              </Col> */}
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  {status === "Ikke bekreftet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusUnknown
                          className="icon_ikkebekreftet"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Bekreftet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusDisabled
                          className="icon_bekreftet"
                          color="[#95bb72]"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Levert" ? (
                    <>
                      <div className="float-right">
                        <GrStatusGood className="icon_levert" size={26} />
                      </div>
                      <div className="lg:ml-2">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div className="float-right">
                    <BsPeopleFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {numPersons}
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <Container>
            <Row className="pt-3 lg:px-3" lg={1} xs={1}>
              <div className="float-right lg:pl-10">
                <Col xs={{ span: 12 }}>
                  <Button
                    variant="secondary"
                    className="text-white border-none bg-[#ff4122] text-left float-right inline ml-2"
                    onClick={onClickCancelOrder}
                  >
                    Kanseller
                  </Button>
                </Col>
                {status === "Ikke bekreftet" ? (
                  <div>
                    {" "}
                    <Col xs={{ span: 12 }}>
                      <Button
                        variant="secondary"
                        className={`border-[#94CD74] bg-[#94CD74] text-left float-right inline ml-2 hover:bg-[#94CD74] hover:text-white `}
                        onClick={onClickConfirmOrder}
                        disabled={disableConfirmOrderButton}
                      >
                        <div className="flex">
                          <div className={`animate-bounce ${hidden}`}>
                            <GiCookingPot size={22} className="mr-1" />
                          </div>
                          Bekreft ordre
                        </div>
                      </Button>
                    </Col>
                  </div>
                ) : null}

                <Col xs={{ span: 12 }}>
                  <Button
                    variant="secondary"
                    className={`!text-button border-none text-left float-right inline mr-2`}
                    onClick={onClickCommentInternal}
                  >
                    <GiNotebook size={26} />
                  </Button>
                </Col>

                <Col xs={{ span: 12 }}>
                  <Button
                    variant="secondary"
                    className={`text-[#94CD74] border-none text-left float-right inline mr-2 ${visibilietyComment}`}
                    onClick={onClickComment}
                  >
                    <FaRegCommentDots size={26} />
                  </Button>
                </Col>
                <Col xs={{ span: 12 }}>
                  <Button
                    variant="secondary"
                    className={`text-black border-none text-left float-right inline mr-2 ${visibilietyNoComment}`}
                    onClick={onClickComment}
                  >
                    <FaRegComment size={26} />
                  </Button>
                </Col>
              </div>
            </Row>
          </Container>
        </Card>
      </Col>
    </div>
  );
};

export default AdminCard;
