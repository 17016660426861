//@ts-nocheck
import React from "react";
import { useEffect } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase.ts";

import { HiOutlineUserCircle } from "react-icons/hi";

const LoadingPage = () => {
  let url = window.location.href;
  const functions = getFunctions(app);

  const [code, setCode] = React.useState("");
  const createVippsLogin = httpsCallable(functions, "createVippsLogin");

  function getParam(key) {
    var queryString = url.split("?").pop();
    var params = {};
    queryString
      .split("&")
      .forEach((i) => (params[i.split("=")[0]] = i.split("=")[1]));
    setCode(params[key]);
    return params[key] || null;
  }

  let data = {
    code: code,
  };

  function createLogin() {
    createVippsLogin(data)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (url) {
      getParam("code");
    }
  }, [url]);

  useEffect(() => {
    if (code !== ""){
        createLogin();
    }
  }, [code]);

  return (
    <>
      {code ? (
        <div className="w-full min-h-screen flex justify-center items-center">
          <svg className="w-28 h-28 animate-bounce" fill="none" viewBox="0 0 24 24">
            <HiOutlineUserCircle />
          </svg>
        </div>
      ) : (
        <>
          <h1>Noe gikk galt...</h1>
          <h4>Vennligst prøv igjen senere</h4>
        </>
      )}
    </>
  );
};

export default LoadingPage;
