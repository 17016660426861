import { Modal } from "react-bootstrap";
import React, { FC } from "react";

interface ModalComponentProps {
  title?: string;
  body?: any;
  show?: any;
  hide?: any;
  footer?: any;
}

const ModalComponent: FC<ModalComponentProps> = ({
  title = "",
  body,
  show,
  hide,
  footer,
}) => {
  return (
    <>
      <Modal show={show} onHide={hide} centered>
        <Modal.Header
          closeButton
          className="bg-[#D6CBC1] text-white tracking-wider"
        >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
