//@ts-nocheck
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import FoodCard from "../components/FoodCard.tsx";

import popular1 from "../img/tapas_eksempel.jpeg";
import popular2 from "../img/tapas_eksempel2.jpeg";
import forside from "../img/forside.png";

import "../style/home.css";
import "../style/button.css";
import { useNavigate } from "react-router-dom";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import ToggleSimple from "../components/ToggleSimple.tsx";
import ModalComponent from "../components/ModalComponent.tsx";

import {
  query,
  collection,
  getDocs,
  where,
  DocumentData,
} from "firebase/firestore";

import { db, app } from "../firebase.ts";

const Home = () => {
  let navigation = useNavigate();

  const [location, setLocation] = useState(
    localStorage.getItem("location") || ""
  );

  const [choseLocation, setChoseLocation] = useState(false);
  const [supplierData, setSupplierData] = useState();
  const [supplierId, setSupplierId] = useState();
  const [homepageCourses, setHomepageCourses] = useState();

  useEffect(() => {
    if (location === "") {
      setChoseLocation(true);
    }

    if (location !== "") {
      fetchSuppliersBasedOnCity();
    }
  }, [location]);

  useEffect(() => {
    if (supplierData) {
      const slicedArray = supplierData[0].menus.slice(0, 3);
      setHomepageCourses(slicedArray);
    }
  }, [supplierData]);

  async function fetchSuppliersBasedOnCity() {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "suppliers"), where("city", "==", location));
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setSupplierId(doc.id);
        list.push({ id: doc.id, ...doc.data() });
      });
      setSupplierData([...list]);
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  const routeChange = (path: string) => {
    navigation(path);
  };

  return (
    <>
      {location === "" ? (
        <></>
      ) : (
        <>
          <div className="pt-16 ">
            <Container>
              <Row>
                <Col lg={{ span: 7 }}>
                  <div>
                    <div className="flex">
                      <h1 className="font-montserrat lg:text-6xl text-5xl">
                        {" "}
                        Catering i {location},
                      </h1>{" "}
                      {/* <ToggleSimple /> */}
                    </div>
                    <br />
                    <h1 className="font-montserrat lg:text-5xl text-5xl">
                      helt enkelt.
                    </h1>
                  </div>

                  <Button
                    variant="secondary"
                    className="focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform"
                    onClick={(e) => routeChange("/menyer")}
                    style={{
                      fontWeight: 500,
                      backgroundColor: "#DA271B",
                      borderColor: "#DA271B",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      paddingTop: "1.0rem",
                      paddingBottom: "1.0rem",
                      marginTop: "1.5rem",
                      marginRight: "1.5rem",
                    }}
                  >
                    SE MENY
                  </Button>
                  <Button
                    variant="secondary"
                    className="btn-custom focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform"
                    onClick={(e) => {
                      routeChange("/logginn-bedrift");
                    }}
                    style={{
                      fontWeight: 800,
                      color: "#423629",
                      borderColor: "#423629",
                      backgroundColor: "transparent",
                      border: "4px solid",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      paddingTop: "1.0rem",
                      paddingBottom: "1.0rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    BEDRIFT
                  </Button>
                  <br />
                  <h5 className="mt-5 font-normal lg:w-9/12 leading-9">
                    <strong>Du velger maten, vi sørger for resten.</strong> Fra
                    utvalgte leverandører og erfarne kokker for en smakfull
                    opplevelse!
                  </h5>
                </Col>
                <Col lg={{ span: 5 }} className="lg:mt-0 mt-4">
                  <img src={forside} className="rounded-full " alt="" />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="!bg-beige pb-20">
            <div className="wave-container"></div>
            <Container>
              <h1 className="font-montserrat text-left lg:pt-10 pt-14 text-footer flex lg:text-4xl text-3xl">
                Bestill mat på <div className="text-button pl-2">1, 2, 3</div>
              </h1>

              <Row className="justify-content-md-center">
                <Col md={{ span: 4 }} className="g-5">
                  <div className="flex">
                    <div className="flex">
                      {" "}
                      <div className="circle">
                        {" "}
                        <RiNumber1 color="#DA271B" />{" "}
                      </div>
                    </div>
                    <h3 className="pt-2.5 pl-2 tracking-wide text-3xl">
                      Velg din favorittmat
                    </h3>
                  </div>
                  <h6 className="pt-2 px-2 tracking-wide leading-7">
                    Bla gjennom våre menyer og velg maten som får ganen til å
                    danse.
                  </h6>
                </Col>
                <Col md={{ span: 4 }} className="g-5 ">
                  <div className="flex">
                    {" "}
                    <div className="circle">
                      {" "}
                      <RiNumber2 color="#DA271B" />{" "}
                    </div>
                    <h3 className="pt-2.5 pl-2 tracking-wide text-3xl">
                      Bekreft og betal
                    </h3>
                  </div>
                  <h6 className="pt-2 px-2 tracking-wide leading-7">
                    Trykk på bestill-knappen og betal via plattformen. Du vil
                    motta en bekreftelse på e-post eller SMS når bestillingen er
                    godkjent
                  </h6>
                </Col>
                <Col md={{ span: 4 }} className="g-5 ">
                  <div className="flex">
                    {" "}
                    <div className="circle">
                      {" "}
                      <RiNumber3 color="#DA271B" />{" "}
                    </div>
                    <h3 className="pt-2.5 pl-2 text-3xl">Få maten levert</h3>
                  </div>
                  <h6 className="pt-2 px-2 tracking-wide leading-7">
                    Maten blir levert til ditt arrangement, akkurat når du vil.
                    Du kan også hente maten selv.
                  </h6>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="wave-container1"></div>
          <div className="pb-20">
            <h1 className="font-montserrat text-center pt-12 pb-6 text-footer text-4xl">
              Populære menyer
            </h1>
            <Container>
              <Row className="justify-content-md-center rounded-xl">
                {!homepageCourses ? (
                  <null></null>
                ) : (
                  <>
                    {homepageCourses.map((menu) => {
                      return (
                        <Col md={{ span: 4 }} className="g-4">
                          <FoodCard
                            img={menu.img}
                            menuId={menu.id}
                            name={menu.name}
                            supplierId={supplierId}
                          />
                        </Col>
                      );
                    })}
                  </>
                )}
              </Row>
              <div className="flex justify-center">
                <Button
                  variant="secondary"
                  className="focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform"
                  onClick={(e) => routeChange("/menyer")}
                  style={{
                    fontWeight: 500,
                    backgroundColor: "#DA271B",
                    borderColor: "#DA271B",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    paddingTop: "1.0rem",
                    paddingBottom: "1.0rem",
                    marginTop: "1.5rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  SE ALLE MENYER
                </Button>
              </div>
            </Container>
          </div>
        </>
      )}

      {/* Modal for å legge inn kommentar internt på ordre*/}
      <ModalComponent
        show={choseLocation}
        title="Hvor holder du til ?"
        hide={() => {
          setLocation("Trondheim");
          localStorage.setItem("location", "Trondheim");
          setChoseLocation(false);
        }}
        body={
          <>
            <Container className="w-80">
              <Row className="mt-2 mb-2">
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 800,
                    color: "#423629",
                    borderColor: "#423629",
                    backgroundColor: "transparent",
                    border: "4px solid",
                    paddingLeft: "1.0rem",
                    paddingRight: "1.0rem",
                    paddingTop: "0.8rem",
                    paddingBottom: "0.8rem",
                  }}
                  onClick={() => {
                    setLocation("Trondheim");
                    localStorage.setItem("location", "Trondheim");
                    setChoseLocation(false);
                  }}
                >
                  Trondheim
                </Button>
              </Row>
              {/* <Row className="mt-2 mb-2">
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 800,
                    color: "#423629",
                    borderColor: "#423629",
                    backgroundColor: "transparent",
                    border: "4px solid",
                    paddingLeft: "1.0rem",
                    paddingRight: "1.0rem",
                    paddingTop: "0.8rem",
                    paddingBottom: "0.8rem",
                  }}
                  onClick={() => {
                    setLocation("Oslo");
                    localStorage.setItem("location", "Oslo");
                    setChoseLocation(false);
                  }}
                >
                  Oslo
                </Button>
              </Row>  */}
              <Row className="mt-2 mb-2">
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 800,
                    color: "#423629",
                    borderColor: "#423629",
                    backgroundColor: "transparent",
                    border: "4px solid",
                    paddingLeft: "1.0rem",
                    paddingRight: "1.0rem",
                    paddingTop: "0.8rem",
                    paddingBottom: "0.8rem",
                  }}
                  onClick={() => {
                    setLocation("Tønsberg");
                    localStorage.setItem("location", "Tønsberg");
                    setChoseLocation(false);
                  }}
                >
                  Tønsberg
                </Button>
              </Row>


              <Row className="mt-2 mb-2">
                <Button
                  variant="secondary"
                  style={{
                    fontWeight: 800,
                    color: "#423629",
                    borderColor: "#423629",
                    backgroundColor: "transparent",
                    border: "4px solid",
                    paddingLeft: "1.0rem",
                    paddingRight: "1.0rem",
                    paddingTop: "0.8rem",
                    paddingBottom: "0.8rem",
                  }}
                  onClick={() => {
                    setLocation("Stavanger");
                    localStorage.setItem("location", "Stavanger");
                    setChoseLocation(false);
                  }}
                >
                  Stavanger
                </Button>
              </Row>
            </Container>
          </>
        }
      />
    </>
  );
};

export default Home;
