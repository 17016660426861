import { FC } from "react";
import React from "react";
import { Card, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FoodCardProps {
  img?: string;
  menuId?: string;
  name?: string;
  supplierId?: string;
}

const FoodCard: FC<FoodCardProps> = ({ img, menuId, name, supplierId }) => {
  const navigation = useNavigate();

  const navigate = (url: string) => {
    navigation(url);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Col >
        <Card className="border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/menyer/${supplierId}/${menuId}`)}
          >
            <Col >
              <div className="font-medium text-xl tracking-wider">
                <img src={img} className="w-100 h-72 rounded-xl" alt="" />
                <div className="absolute w-full py-2.5 bottom-0 inset-x-0 bg-[#E4DCD4] bg-opacity-50 text-xl text-center text-[#5F5F5F] leading-[16.8rem]  tracking-widest">{name}</div>

              </div>
            </Col>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default FoodCard;
