import React from "react";
import { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";

import { GrStatusUnknown } from "react-icons/gr";
import { GrStatusGood } from "react-icons/gr";
import { GrStatusDisabled } from "react-icons/gr";
import { FaRegComment } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";

interface MyOrdersProps {
  date?: any;
  time?: any;
  menuId?: string;
  orderId?: any;
  phoneNumber?: number;
  totalPrice?: number;
  orderIdLink?: string;
  status?: string;
}

const MyOrders: FC<MyOrdersProps> = ({
  date,
  time,
  menuId = "",
  orderId,
  phoneNumber,
  totalPrice,
  orderIdLink,
  status,
}) => {
  const navigate = useNavigate();

  const navigateToOtherSite = (url: string) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  return (
    <div className="tracking-wide font-light ">
      <Col>
        <Card className="px-6 py-3 mb-4 border-2 rounded-xl hover:drop-shadow-xl hover:text-black shadow-md border-gray">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigateToOtherSite(`/order/${orderIdLink}`)}
          >
            <Col className="py-6 px-3">
              <div className="font-medium text-xl tracking-wider pb-4">
                {date}, kl.{time}
              </div>
              <div>
                <strong className="font-medium">{menuId}</strong> - {orderId}
              </div>
            </Col>
            <hr />
            <Row>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div>
                    <BsFillTelephoneFill size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {phoneNumber}
                    </strong>
                  </div>
                </div>
              </Col>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  <div className="float-right">
                    <MdAttachMoney size={26} />
                  </div>
                  <div className="lg:ml-2 ml-1">
                    <strong className="font-medium text-lg">
                      {totalPrice},-
                    </strong>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="py-6 px-3" lg={{ offset: 1 }}>
                <div className="flex">
                  {status === "Ikke bekreftet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusUnknown
                          className="icon_ikkebekreftet"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Bekreftet" ? (
                    <>
                      <div className="float-right">
                        <GrStatusDisabled
                          className="icon_bekreftet"
                          color="[#95bb72]"
                          size={26}
                        />
                      </div>
                      <div className="lg:ml-2 ml-1">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                  {status === "Levert" ? (
                    <>
                      <div className="float-right">
                        <GrStatusGood className="icon_levert" size={26} />
                      </div>
                      <div className="lg:ml-2">
                        <strong className="font-medium text-lg">
                          {status}
                        </strong>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <hr />
        </Card>
      </Col>
    </div>
  );
};

export default MyOrders;
