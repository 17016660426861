// @ts-nocheck
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import Stripe from "../components/Stripe.tsx";
import Vipps from "../components/Vipps.tsx";

import { getFunctions, httpsCallable } from "firebase/functions";
import { app, db } from "../firebase.ts";

import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { AiOutlineQuestionCircle } from "react-icons/ai"; //question mark
import { AiOutlineCheckCircle } from "react-icons/ai"; //checkmark
import { MdOutlineCancel } from "react-icons/md"; //cancel
import { IconContext } from "react-icons";
import "../style/icons.css";
import { GiCookingPot } from "react-icons/gi";
import "../style/icons.css";

const OrderDetail = () => {
  let orderId = useParams();
  const [order, setOrder] = useState(null);
  const [commentsOnOrder, setCommentsOnOrder] = useState([]);
  const [comment, setComment] = useState("");
  const [supplier, setSupplier] = useState(null);
  const [supplierName, setSupplierName] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierTown, setSupplierTown] = useState("");
  const [supplierZip, setSupplierZip] = useState("");

  const functions = getFunctions(app);
  const createStripeCheckout = httpsCallable(functions, "createStripeCheckout");
  const createVippsCheckout = httpsCallable(functions, "createVippsCheckout");
  const createInvoiceCheckout = httpsCallable(
    functions,
    "createInvoiceCheckout"
  );

  let data = {
    client_reference_id: orderId.orderId,
  };

  const [showVipps, setShowVipps] = useState(false);
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [showFaktura, setShowFaktura] = useState(false);
  const [isShownVipps, setIsShownVipps] = useState(false);
  const [isShownStripe, setIsShownStripe] = useState(false);

  // Menu and courses of order
  const [menu, setMenu] = useState(null);
  const [courses, setCourses] = useState(null);
  const [warmDishes, setWarmDishes] = useState([]);
  const [coldDishes, setColdDishes] = useState([]);
  const [desert, setDesert] = useState([]);
  const [sides, setSides] = useState([]);

  //Invoice details
  const [invoiceName, setInvoiceName] = useState("");
  const [invoiceOrgNumber, setInvoiceOrgNumber] = useState("");
  const [invoiceAddress, setInvoiceAddress] = useState("");
  const [invoicePhone, setInvoicePhone] = useState("");
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [invoiceZip, setInvoiceZip] = useState("");

  //Disable buttons to prevent double click
  const [buttonDisabledInvoiceSent, setButtonDisabledInvoiceSent] =
    useState(false);

  useEffect(() => {
    if (orderId.orderId) {
      let id = orderId.orderId;
      fetchData(id);
    }
  }, [orderId.orderId]);

  async function fetchData(id: string) {
    const docRef = doc(db, "sales", id);
    const list: SetStateAction<DocumentData[]> = [];
    try {
      const doc = await getDoc(docRef);
      const data = doc.data();

      setOrder(data);
      data.commentCustomer.forEach((comment) => {
        list.push(comment);
      });
      await setCommentsOnOrder(list);
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  async function addCommentToOrder() {
    let comments = {
      comment: comment,
      date: new Date(),
      writer: "customer",
    };
    let orderID = orderId.orderId;
    const docRef = doc(db, "sales", orderID);

    await updateDoc(docRef, {
      commentCustomer: arrayUnion(comments),
    })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log("Error getting document:", e);
      });
  }

  async function fetchSupplier(id: string) {
    const docRef = doc(db, "suppliers", id);
    try {
      const doc = await getDoc(docRef);
      const data = doc.data();

      setSupplier(data);
      setSupplierName(data.name);
      setSupplierAddress(data.address);
      setSupplierPhone(data.phonenumber);
      setSupplierTown(data.city);
      setSupplierZip(data.zipcode);
      data.menus.map((menu: any) => {
        if (order.menuName === menu.name) {
          setMenu(menu);
          setCourses(menu.courses);
          setWarmDishes(
            menu.courses.filter(
              (course: { type: string }) => course.type === "hot"
            )
          );
          setColdDishes(
            menu.courses.filter(
              (course: { type: string }) => course.type === "cold"
            )
          );
          setDesert(
            menu.courses.filter(
              (course: { type: string }) => course.type === "dessert"
            )
          );
          setSides(
            menu.courses.filter(
              (course: { type: string }) => course.type === "acc"
            )
          );
        }
      });
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  const stripe = window.Stripe(
    "pk_live_51MPxP6B2bSKmtEdHOZjkpv9cWxQ41ntian5pbJMkTMgCAtOMbsaUcRn5BYwBZf4zX4AVpwCpPDYUZwJUZbttBEKz00bS0sOEyD"
  );

  function invoiceThing(data: any) {
    console.log(data);
    createInvoiceCheckout(data)
      .then((response) => {
        window.location.href = "/bestilt/c571b0b9-3834-4f30";
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function stripeThing(data: any) {
    createStripeCheckout(data).then((response) => {
      const sessionId = response.data.id;
      const client_reference_id = response.data.client_reference_id;
      stripe.redirectToCheckout({
        sessionId: sessionId,
        clientReferenceId: client_reference_id,
      });
    });
  }

  useEffect(() => {
    if (order) {
      fetchSupplier(order.supplierId);
      console.log(order);
      console.log(menu);
    }
  }, [order]);

  function vippsThing(data: any) {
    createVippsCheckout(data).then((response) => {
      const clientUrl = response.data.url;
      //Send bruker til clientUrl
      window.location.href = clientUrl;
    });
  }

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  async function addInvoiceAsPayment() {
    setButtonDisabledInvoiceSent(true);
    let invoice = {
      name: invoiceName,
      orgNumber: invoiceOrgNumber,
      address: invoiceAddress,
      zip: invoiceZip,
      email: invoiceEmail,
      phonenumber: invoicePhone,
    };
    let orderID = orderId.orderId;
    let invoiceDocRef = doc(db, "sales", orderID);
    await updateDoc(invoiceDocRef, {
      invoice: invoice,
    })
      .then((e) => {
        invoiceThing(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div>
      {order === null && menu === null ? (
        <div></div>
      ) : (
        <>
          <div>
            {order.paymentStatus === "paid" ? (
              <>
                <Row>
                  <Col className="pl-10 pt-10 pb-2 pr-10">
                    <div className="pb-2 font-normal text-3xl tracking-wider">
                      Din bestilling hos {supplierName}
                    </div>

                    <Card className="text-black bg-white text-left w-12/12 mt-6 shadow-md border-gray pl-2 md:mr-5">
                      <Card.Body className="pt-4 pr-1 pl-1 pb-2.5 ">
                        <>
                          <Row>
                            <Col
                              md={{ span: 1, offset: 0 }}
                              xs={{ span: 1, offset: 4 }}
                              className="lg:pl-8 lg:pb-0 md:pl-8 md:pb-0 pl-5 pb-2"
                            >
                              {order.status === "Ikke bekreftet" ? (
                                <IconContext.Provider
                                  value={{ color: "#6495ed", size: "54px" }}
                                >
                                  <div>
                                    <AiOutlineQuestionCircle />
                                  </div>
                                </IconContext.Provider>
                              ) : null}
                              {order.status === "Bekreftet" ? (
                                <div>
                                  <IconContext.Provider
                                    value={{ color: "#94CD74", size: "54px" }}
                                  >
                                    <div>
                                      <AiOutlineCheckCircle />
                                    </div>
                                  </IconContext.Provider>
                                </div>
                              ) : null}
                              {order.status === "Kansellert" ? (
                                <div>
                                  <IconContext.Provider
                                    value={{ color: "#DA271B", size: "54px" }}
                                  >
                                    <div>
                                      <MdOutlineCancel />
                                    </div>
                                  </IconContext.Provider>
                                </div>
                              ) : null}
                            </Col>
                            <Col
                              className="md:-ml-5"
                              md={{ span: 4, offset: 0 }}
                            >
                              <div className="text-xs">
                                STATUS PÅ BESTILLING
                              </div>
                              <div className="text-lg mt-2 md:mb-0 mb-2">
                                {order.status}
                              </div>
                            </Col>

                            <Col className="" md={{ span: 4, offset: 0 }}>
                              <div className="text-xs">DATO OG TIDSPUNKT</div>
                              <div className="text-lg mt-2 md:mb-0 mb-2">
                                {capitalize(
                                  new Date(
                                    order.delivery.date.seconds * 1000
                                  ).toLocaleString(`no-NO`, options)
                                )}
                                , kl.{" "}
                                {new Date(
                                  order.delivery.time.seconds * 1000
                                ).toLocaleTimeString(`no-NO`, {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </Col>
                            <Col className="" md={{ span: 3, offset: 0 }}>
                              <div className="text-xs">LEVERING</div>
                              <div className="text-lg mt-2 md:mb-0 mb-2">
                                {order.delivery.method === "Henting" &&
                                supplier ? (
                                  <div>
                                    <div>Henter selv hos {supplierName}</div>
                                    <div className="text-md italic">
                                      {supplier.address}, {supplier.zipcode}{" "}
                                      {supplier.city}
                                    </div>
                                  </div>
                                ) : (
                                  <div>Leveres til {order.delivery.adress}</div>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {order.status === "Ikke bekreftet" ? (
                              <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#6495ed]" />
                            ) : null}
                            {order.status === "Bekreftet" ? (
                              <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#94CD74]" />
                            ) : null}
                            {order.status === "Kansellert" ? (
                              <hr className="w-11/12 mt-2 m-auto h-1 border-2 !border-[#DA271B]" />
                            ) : null}
                          </Row>
                        </>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row md={2}>
                  <Col md={{ span: 7 }} className="pl-10 pb-2">
                    <div className="pb-2 pt-10 font-normal text-2xl tracking-wider">
                      {capitalize(order.menuName)}
                    </div>
                    <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2 mb-10">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <>
                          {warmDishes.length > 0 ? (
                            <div className="py-2 text-lg">
                              VARME RETTER
                              {warmDishes.map((dish) => {
                                return (
                                  <Row>
                                    <div className="flex g-2">
                                      <Col md={{ span: 12 }}>
                                        <h6 className="font-montserrat text-md flex pl-4">
                                          <GiCookingPot
                                            size={28}
                                            className="icon_food mt-1 absolute"
                                          />
                                          <div className="pt-2 ml-9 mr-4">
                                            {dish.name}
                                          </div>
                                        </h6>
                                      </Col>
                                    </div>
                                  </Row>
                                );
                              })}{" "}
                            </div>
                          ) : null}
                          {coldDishes.length > 0 ? (
                            <div className="py-3 text-lg">
                              KALDE RETTER
                              {coldDishes.map((dish) => {
                                return (
                                  <Row>
                                    <div className="flex g-2">
                                      <Col md={{ span: 12 }}>
                                        <h6 className="font-montserrat text-md flex pl-4">
                                          <GiCookingPot
                                            size={28}
                                            className="icon_food mt-1 absolute"
                                          />
                                          <div className="pt-2 ml-9 mr-4">
                                            {dish.name}
                                          </div>
                                        </h6>
                                      </Col>
                                    </div>
                                  </Row>
                                );
                              })}{" "}
                            </div>
                          ) : null}
                          {desert.length > 0 ? (
                            <div className="py-3 text-lg">
                              DESSERT
                              {desert.map((dish) => {
                                return (
                                  <Row>
                                    <div className="flex g-2">
                                      <Col md={{ span: 12 }}>
                                        <h6 className="font-montserrat text-md flex pl-4">
                                          <GiCookingPot
                                            size={28}
                                            className="icon_food mt-1 absolute"
                                          />
                                          <div className="pt-2 ml-9 mr-4">
                                            {dish.name}
                                          </div>
                                        </h6>
                                      </Col>
                                    </div>
                                  </Row>
                                );
                              })}{" "}
                            </div>
                          ) : null}
                          {sides.length > 0 ? (
                            <div className="py-3 text-lg">
                              TILBEHØR
                              {sides.map((dish) => {
                                return (
                                  <Row>
                                    <div className="flex g-2">
                                      <Col md={{ span: 12 }}>
                                        <h6 className="font-montserrat text-md flex pl-4">
                                          <GiCookingPot
                                            size={28}
                                            className="icon_food mt-1 absolute"
                                          />
                                          <div className="pt-2 ml-9 mr-4">
                                            {dish.name}
                                          </div>
                                        </h6>
                                      </Col>
                                    </div>
                                  </Row>
                                );
                              })}{" "}
                            </div>
                          ) : null}
                        </>
                      </Card.Body>
                    </Card>
                    {order.chosenExtras ? (
                      <>
                        <div className="pb-2 font-normal text-2xl tracking-wider">
                          Tilleggsretter
                        </div>
                        <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2 mb-10">
                          <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                            {order.chosenExtras ? (
                              <div className="pb-3">
                                {order.chosenExtras.map((dish) => {
                                  return (
                                    <Row>
                                      <div className="flex g-2">
                                        <Col md={{ span: 12 }}>
                                          <h6 className="font-montserrat text-md flex pl-4">
                                            <GiCookingPot
                                              size={28}
                                              className="icon_food mt-1 absolute"
                                            />
                                            <div className="pt-2 ml-9 mr-4">
                                              {dish.name}
                                            </div>
                                          </h6>
                                        </Col>
                                      </div>
                                    </Row>
                                  );
                                })}
                              </div>
                            ) : null}
                          </Card.Body>
                        </Card>
                      </>
                    ) : null}
                  </Col>
                  <Col md={{ span: 5 }} className="pb-2 pl-10">
                    <div className="pb-2 lg:pt-10  font-normal text-xl tracking-wider">
                      Leverandørchat
                    </div>
                    <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2">
                      <Card.Body className="pt-4 pr-3 pl-1 pb-3">
                        {commentsOnOrder.length > 0 ? (
                          <div>
                            {commentsOnOrder.map((comment) => {
                              return (
                                <>
                                  {comment.comment !== "" ? (
                                    <>
                                      {comment.writer === "customer" ? (
                                        <div className="lg:w-10/12 lg:float-right p-2 border-beige text-white rounded-xl mb-2 bg-beige flex">
                                          {" "}
                                          <div className="float-left w-11/12 lg:w-12/12">
                                            {comment.comment}
                                          </div>
                                          <div className="text-sm float-right">
                                            {new Date(
                                              comment.date.seconds * 1000
                                            ).toLocaleDateString(`no-NO`)}
                                            ,{" "}
                                            {new Date(
                                              comment.date.seconds * 1000
                                            ).toLocaleTimeString(`no-NO`, {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="lg:w-10/12  p-2 border-beige border-2 text-beige rounded-xl bg-white lg:float-left flex">
                                          {" "}
                                          <div className="float-left font-bold w-7/12 lg:w-12/12">
                                            {comment.comment}
                                          </div>
                                          <div className="text-sm float-right">
                                            {new Date(
                                              comment.date.seconds * 1000
                                            ).toLocaleDateString(`no-NO`)}
                                            ,{" "}
                                            {new Date(
                                              comment.date.seconds * 1000
                                            ).toLocaleTimeString(`no-NO`, {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                        <textarea
                          type="text"
                          className="w-full h-18 border border-gray-400 rounded px-3 py-2 outline-none text-gray-600"
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Ny melding"
                        />
                        <Button
                          className="float-right bg-button border-button hover:bg-button hover:bg-border"
                          onClick={() => addCommentToOrder()}
                        >
                          Send melding
                        </Button>
                      </Card.Body>
                    </Card>

                    <div className="pb-2 pt-10 font-normal text-xl tracking-wider">
                      Leverandørinformasjon
                    </div>
                    <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2">
                      <Card.Body className="pt-3 pr-3 pl-3 pb-3">
                        <div className="tracking-wider font-light">
                          {supplierName}
                        </div>
                        <div className="tracking-wider font-light">
                          {supplierPhone}
                        </div>
                        <div className="tracking-wider font-light">
                          {supplierAddress}
                        </div>
                        <div className="tracking-wider font-light">
                          {supplierZip} {supplierTown}
                        </div>
                      </Card.Body>
                    </Card>
                    <div className="pb-2 pt-10 font-normal text-xl tracking-wider">
                      Pris og betaling
                    </div>
                    <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2 mb-10">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          <div>
                            {menu != null ? (
                              <>
                                <Row md={1} className="p-4">
                                  <Col xs={6} md={8}>
                                    <div className="tracking-widest">
                                      <div>
                                        {order.menuName} x {order.peopleCount}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={6} md={4}>
                                    <div className="tracking-widest text-right mr-2">
                                      {menu.price * order.peopleCount},-
                                    </div>
                                  </Col>
                                </Row>
                                <hr className="mr-4" />
                                {order.chosenExtras ? (
                                  <>
                                    <Row md={1} className="p-4">
                                      <Col xs={6} md={8}>
                                        <div className="tracking-widest">
                                          <div>Tilleggsretter</div>
                                          {order.chosenExtras.map((extra) => {
                                            return (
                                              <div className="pl-4 pt-2 italic">
                                                {extra.name}, {extra.count}{" "}
                                                strykk
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </Col>
                                      <Col xs={6} md={4}>
                                        <div className="tracking-widest text-right mr-2">
                                          {order.extrasPrice},-
                                          {order.chosenExtras.map((extra) => {
                                            return (
                                              <div className="pl-4 pt-2 italic">
                                                {extra.price * extra.count},-
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="border-2 mr-4"></div>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                            <Row md={1} className="p-4">
                              <Col xs={6} md={8}>
                                <div className="tracking-widest">
                                  <div>Totalpris</div>
                                  <div className="text-xs text-[#808080]">
                                    (Totalsum inkludert 15% MVA)
                                  </div>
                                </div>
                              </Col>
                              <Col xs={6} md={4}>
                                <div className="tracking-widest text-right mr-2">
                                  {order.totalPrice},-
                                </div>
                              </Col>
                            </Row>
                            <div></div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2 mb-10">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          <Row md={1} className="px-4 py-2">
                            <Col xs={6} md={8}>
                              <div className="tracking-widest">
                                <div>Betalt med</div>
                              </div>
                            </Col>
                            <Col xs={6} md={4}>
                              <div className="tracking-widest text-right mr-2">
                                {order.payment}
                              </div>
                            </Col>
                          </Row>
                          <hr className="mr-4" />
                          <Row md={1} className="px-4 py-2">
                            <Col xs={6} md={5}>
                              <div className="tracking-widest">
                                <div>Ordrenummer</div>
                              </div>
                            </Col>
                            <Col xs={6} md={7}>
                              <div className="tracking-widest text-right mr-2">
                                {orderId.orderId}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                    {order.payment === "Faktura" ? (
                      <div>
                        <Card className="text-black bg-white text-left w-11/12 mt-6 shadow-md border-gray pl-2 mb-10">
                          <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                              <Row md={1} className="px-4 py-2">
                                <Col xs={8} md={8}>
                                  <div className="tracking-widest">
                                    <div>Fakturainformasjon</div>
                                  </div>
                                </Col>
                              </Row>
                              <hr className="mr-4" />
                              <Row md={1} className="px-4 py-2">
                                <Col xs={5} md={5}>
                                  <div className="tracking-widest">
                                    <div>Navn</div>
                                  </div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.name}
                                  </div>
                                </Col>
                              </Row>
                              <Row md={1} className="px-4 py-2">
                                <Col xs={5} md={5}>
                                  <div className="tracking-widest">
                                    <div>Org. nummer</div>
                                  </div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.orgNumber}
                                  </div>
                                </Col>
                              </Row>

                              <Row md={1} className="px-4 py-2">
                                <Col xs={5} md={5}>
                                  <div className="tracking-widest">
                                    <div>Adresse</div>
                                  </div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.address}
                                  </div>
                                </Col>
                              </Row>
                              <Row md={1} className="px-4 py-2">
                                <Col xs={5} md={5}>
                                  <div className="tracking-widest">
                                    <div>Postnummer</div>
                                  </div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.zip}
                                  </div>
                                </Col>
                              </Row>
                              <Row md={1} className="px-4 py-2">
                                <Col xs={5} md={5}>
                                  <div className="tracking-widest">
                                    <div>Mobil</div>
                                  </div>
                                </Col>
                                <Col xs={7} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.phonenumber}
                                  </div>
                                </Col>
                              </Row>
                              <Row md={1} className="px-4 py-2">
                                <Col xs={3} md={5}>
                                  <div className="tracking-widest">
                                    <div>E-post</div>
                                  </div>
                                </Col>
                                <Col xs={9} md={7}>
                                  <div className="tracking-widest text-right mr-2">
                                    {order.invoice.email}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Container className="contact-content flex">
                  <Row className="mt-2 justify-content-center">
                    <Col className="mb-4" xs={12} md={12}>
                      <div className="pt-10 font-normal text-3xl tracking-wider whitespace-pre">
                        Velg betalingsmetode
                      </div>
                    </Col>

                    <Col className="mb-4" xs={6} md={4} sm={6}>
                      <Card
                        className="p-2 cursor-pointer"
                        onClick={() => {
                          setIsShownStripe(true);
                        }}
                      >
                        <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                          {isShownStripe ? (
                            <div
                              style={{
                                width: "100%",
                                height: "25vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <Col
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                  minHeight: "25vh",
                                }}
                              >
                                <div className="flex justify-center items-center ml-9 mt-20">
                                  <svg
                                    className="w-36 h-36 animate-bounce"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <GiCookingPot className="icons_loading" />
                                  </svg>
                                </div>
                              </Col>
                            </div>
                          ) : (
                            <img
                              alt="card"
                              onClick={() => {
                                <div className="flex justify-center items-center ml-9 mt-20">
                                  <svg
                                    className="w-36 h-36 animate-bounce"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <GiCookingPot className="icons_loading" />
                                  </svg>
                                </div>;
                                stripeThing(data);
                              }}
                              style={{
                                width: "100%",
                                height: "25vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              src={
                                "https://storage.googleapis.com/partylyd2022.appspot.com/images/stripe.png?fbclid=IwAR2IXkxNAkbeHbXXxrxJ8-T14vnU4mNhTiMlDWeNaGIlM_zWIMm6ah1F5I8"
                              }
                            />
                          )}
                        </Card.Body>
                        <Card.Footer>
                          <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                            Betalingskort
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>
                    <Col className="mb-4" xs={6} md={4} sm={6}>
                      <Card className="p-2 cursor-pointer">
                        <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                          <img
                            alt="faktura"
                            onClick={() => {
                              setShowFaktura(true);
                            }}
                            style={{
                              width: "100%",
                              height: "25vh",
                              objectFit: "contain",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            src={
                              "https://storage.googleapis.com/partylyd2022.appspot.com/images/invoiceIkon.jpeg"
                            }
                          />
                        </Card.Body>
                        <Card.Footer>
                          <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                            Faktura
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col className="mb-4 " xs={6} md={4} sm={6}>
                      <Card
                        className="p-2 " //cursor-pointer
                        /* onClick={() => {
                          setIsShownVipps(true);
                          setPaymentType("Vipps");
                        }}  */
                      >
                        <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                          {isShownVipps ? (
                            <div
                              style={{
                                width: "100%",
                                height: "25vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              {/*  <Col
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                  minHeight: "25vh",
                                  opacity: "0.5",
                                }}
                              >
                                <div className="flex justify-center items-center ml-9 mt-20">
                                  <svg
                                    className="w-36 h-236 animate-bounce"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <GiCookingPot className="icons_loading" />
                                  </svg>
                                </div>
                              </Col> */}
                            </div>
                          ) : (
                            <img
                              alt="vipps"
                              /* onClick={() => {
                                <div className="flex justify-center items-center ml-9 mt-20 disabled">
                                  <svg
                                    className="w-36 h-36 animate-bounce"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <GiCookingPot className="icons_loading" />
                                  </svg>
                                </div>;
                                vippsThing(data);
                              }} */
                              style={{
                                width: "100%",
                                position: "relative",
                                height: "25vh",
                                objectFit: "contain",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                opacity: "0.5",
                              }}
                              src={
                                "https://storage.googleapis.com/partylyd2022.appspot.com/images/vipps_logo_rgb.png?fbclid=IwAR2k_cJAHGNrn-hcXgPHvDhkgjVYfadPb-56zQZhnNJbMAlT3BW3fldNZlc"
                              }
                            />
                          )}
                        </Card.Body>
                        <Card.Footer>
                          <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                            Kommer snart
                          </div>
                        </Card.Footer>
                      </Card>
                    </Col>

                    <Col className="mb-4" xs={11} md={9}>
                      <div>
                        {" "}
                        {showVipps && <Vipps totalSum={order.totalPris} />}{" "}
                      </div>
                      <div> {showCardPayment && <Stripe />} </div>
                    </Col>
                  </Row>
                </Container>
                <Container className="pb-10">
                  {showFaktura === true ? (
                    <>
                      <div className="pb-3 pt-2 font-normal text-2xl tracking-wider whitespace-pre">
                        Faktura
                      </div>
                      <div className="pb-4">
                        Faktura sendes på e-post, og forfaller 10 dager etter
                        første lånedato. Et fakturagebyr på NOK 45,- kommer i
                        tillegg. Totalbeløp blir{" "}
                        <strong>{order.totalPrice + 45},-</strong>.
                      </div>
                      <Form>
                        <div className="text-xs pb-2 text-[#24292e] tracking-widest">
                          PERSONLIA
                        </div>
                        <Row md={2}>
                          <Form.Group
                            className="mb-3"
                            controlId="formNavnMottaker"
                          >
                            <Form.Label>Navn på fakturamottaker</Form.Label>
                            <Form.Control
                              placeholder="Skriv inn navn "
                              value={invoiceName}
                              onChange={(e) => {
                                setInvoiceName(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formOrgNummer"
                          >
                            <Form.Label>Org.nr. for bedrift</Form.Label>
                            <Form.Control
                              placeholder="eksempel 911 111 111"
                              value={invoiceOrgNumber}
                              onChange={(e) => {
                                setInvoiceOrgNumber(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formAdresse">
                            <Form.Label>Adresse</Form.Label>
                            <Form.Control
                              placeholder="Skriv inn adresse "
                              value={invoiceAddress}
                              onChange={(e) => {
                                setInvoiceAddress(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formPostNummer"
                          >
                            <Form.Label>Postnummer</Form.Label>
                            <Form.Control
                              placeholder="eksempel, 1111"
                              value={invoiceZip}
                              onChange={(e) => {
                                setInvoiceZip(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <div className="text-xs pb-2 pt-2 text-[#24292e] tracking-widest">
                          KONTAKTINFORMASJON
                        </div>
                        <Row md={2}>
                          <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>E-post</Form.Label>
                            <Form.Control
                              placeholder="Skriv inn epost "
                              value={invoiceEmail}
                              onChange={(e) => {
                                setInvoiceEmail(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formMobil">
                            <Form.Label>Mobilnummer</Form.Label>
                            <Form.Control
                              placeholder="eksempel, 911 11 999"
                              value={invoicePhone}
                              onChange={(e) => {
                                setInvoicePhone(e.target.value);
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Button
                            className="text-white bg-button border-button mt-3 pt-3 pb-3 ml-2 w-80"
                            variant="secondary"
                            disabled={buttonDisabledInvoiceSent}
                            onClick={() => {
                              addInvoiceAsPayment();
                            }}
                          >
                            SEND FAKTURA
                          </Button>
                        </Row>
                      </Form>
                    </>
                  ) : null}
                </Container>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetail;
