import React from "react";
import { FC } from "react";
import { Button, Card, Col } from "react-bootstrap";

interface MenuCardProps {
  img: string;
  title: string;
  description: string;
  price: string;
  onClick: () => void;
}

const MenuCard: FC<MenuCardProps> = ({
  img,
  title,
  price,
  description,
  onClick,
}) => {
  return (
    <div>
      <Col>
        <Card onClick={onClick} className="border-0 !bg-background">
          <div className="h-60 relative">
            <img
              src={img}
              alt=""
              className="absolute top-0 left-0 object-cover rounded-2xl w-full h-full"
            />
          </div>
          <div className="flex">
            <h2 className="float-left pt-3 text-2xl font-montserrat pb-3">
              {" "}
              {title}
            </h2>
            <h5 className="float-right pt-4 text-md font-bold italic font-montserrat pb-3 text-right ml-auto">
              {" "}
              Fra {price},-
            </h5>
          </div>
          <h6 className="pb-3">{description}</h6>
          <Col md={{ span: 4 }}>
            <Button className="!bg-button !border-button focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform">
              Se meny
            </Button>
          </Col>
        </Card>
      </Col>
    </div>
  );
};

export default MenuCard;
