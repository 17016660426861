import { Col, Container, Button, Row } from "react-bootstrap";
import React, { useState } from "react";
import {
  collection, addDoc
} from "firebase/firestore";
import { db } from "../firebase.ts";
// @ts-ignore
import logo from "../img/kasserolle_logo_rod.png";
import { useNavigate } from "react-router-dom";
import "../style/home.css";



const OrderComplete = () => {
  const navigate = useNavigate();
  const [showInput, setShowInput] = useState(false);

  const handleClick = () => {
    navigate("/finn-bestilling");
  };

  const handleSend = async () => {
    const selectedOption = document.querySelector('input[name="innsikt"]:checked');
    let answer = selectedOption ? selectedOption.value : '';
  
    // Hvis "Annet" er valgt, hent teksten fra inputfeltet
    if (answer === 'Annet') {
      answer = document.getElementById('annetInput').value;
    }
  
    // Lagre svaret i localStorage
    localStorage.setItem("innsiktSvar", answer);
  
    // Legg til svaret i Firestore
    const surveyCollection = collection(db, 'surveyResponses');
  
    try {
      await addDoc(surveyCollection, {
        answer: answer,
        timestamp: new Date()
      });
      console.log("Data lagret i Firestore");
    } catch (error) {
      console.error("Feil ved lagring i Firestore:", error);
    }
  
    // Skjul spørreskjemaet
    const surveyDiv = document.getElementById("surveyDiv");
    if (surveyDiv) {
      surveyDiv.style.display = "none";
    }
  };

  const [showButton, setShowButton] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 'Annet') {
      setShowInput(true);
      setShowButton(true);
    } else {
      setShowInput(false);
      setShowButton(false);
      handleSend(); // Only send when the option isn't "Annet"
    }
  }

  return (
    <div className="grid lg:grid-cols-[repeat(auto-fit,_60%)] grid-cols-[repeat(auto-fit,_100%)] m-auto lg:p-20 p-2 justify-center items-center place-items-center">
      {/* <img src={logo} className="" alt="" /> */}
      
      <div className="font-montserrat lg:text-5xl text-4xl w-full lg:p-8 pt-5 col-span-1 justify-center justify-self-center mx-auto text-center">
        Takk for din bestilling!
      </div>

      <div className="w-full lg:p-8 p-2 pt-4 mb-8 col-span-1 justify-center justify-self-center mx-auto  text-center text-xl content-center">
        Du vil straks motta en ordrebekreftelse på melding med informasjon om
        din bestilling.
      </div>
      <div id="surveyDiv" className="w-full p-8 col-span-1 justify-center justify-self-center mx-auto text-center text-md content-center italic font-montserrat">
    <h2 className="mb-4 text-xl">Hvor hørte du om oss?</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-4">
        {['Venner / Bekjente', 'Søk i Google', 'Visuell annonse', 'Bestilt tidligere', 'Annet'].map(option => (
            <div 
                key={option}
                className={`form-check p-4 ${selectedOption === option ? 'bg-red-500' : 'bg-red-200'} rounded cursor-pointer`}
                onClick={() => handleOptionClick(option)}
            >
                <input 
    type="radio" 
    name="innsikt" 
    value={option} 
    id={option} 
    className="form-check-input hidden" 
    onClick={() => handleOptionClick(option)}
    checked={selectedOption === option}
/>
                <label htmlFor={option} className="form-check-label">{option}</label>
            </div>
        ))}
    </div>
    {selectedOption === 'Annet' && (
   <input type="text" id="annetInput" placeholder="Vennligst spesifiser" className="form-control mt-2 mb-4" />
)}
{showButton && (
   <button onClick={handleSend} className="btn p-3" style={{fontWeight: 600}}>Send svar</button>
)}
</div>

      <div>
        <Button
          className="p-3 col-span-1 mb-10"
          style={{
            backgroundColor: "#423629",
            borderColor: "#423629",
            fontWeight: 600,
          }}
          onClick={handleClick}
        >
          FINN DIN BESTILLING
        </Button>
      </div>
      
      <div className="w-full p-8 col-span-1 justify-center justify-self-center mx-auto  text-center text-md content-center italic">
        Trenger du lyd og lys? Med <strong>KASSEROLLE20</strong> får du{" "}
        <strong>20%</strong> rabatt på din neste bestilling hos{" "}
        <div
          className="font-bold text-[#e57e22] text-lg hover:underline cursor-pointer"
          onClick={() => {
            window.location.href = `https://partylyd.no`;
          }}
        >
          {" "}
          partylyd.no{" "}
        </div>
      </div>
      {
       }
    </div>
  );
};

export default OrderComplete;
