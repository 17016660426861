//@ts-nocheck
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

import { GiCookingPot } from "react-icons/gi";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { FaFish } from "react-icons/fa";
//import allergies
import {
  GiSlicedBread,
  GiBeetleShell,
  GiBigEgg,
  GiPeanut,
  GiJellyBeans,
  GiMilkCarton,
  GiMasonJar,
  GiPlantSeed,
  GiMolecule,
  GiSnail,
  GiThreeLeaves,
} from "react-icons/gi";

import {
  DocumentData,
  query,
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
} from "firebase/firestore";

import { db, app } from "../firebase.ts";

import {
  Col,
  Container,
  Row,
  Card,
  Button,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nb from "date-fns/locale/nb";

import AditionalDishCard from "../components/AditionalDishCard.tsx";
import ModalComponent from "../components/ModalComponent.tsx";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import "../style/icons.css";

function distanceTo(lat1: number, lon1: number, lat2: number, lon2: number) {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;
  return Math.round(dist);
}

function checkAllergy(type: string) {
  const allergyMap = {
    fisk: <FaFish size={28} className="icon_food mt-1"></FaFish>,
    gluten: (
      <GiSlicedBread size={28} className="icon_food mt-1"></GiSlicedBread>
    ),
    skalldyr: (
      <GiBeetleShell size={28} className="icon_food mt-1"></GiBeetleShell>
    ),
    egg: <GiBigEgg size={28} className="icon_food mt-1"></GiBigEgg>,
    peanøtter: <GiPeanut size={28} className="icon_food mt-1"></GiPeanut>,
    nøtter: <GiPeanut size={28} className="icon_food mt-1"></GiPeanut>,
    soya: <GiJellyBeans size={28} className="icon_food mt-1"></GiJellyBeans>,
    melk: <GiMilkCarton size={28} className="icon_food mt-1"></GiMilkCarton>,
    laktose: <GiMilkCarton size={28} className="icon_food mt-1"></GiMilkCarton>,
    selleri: (
      <GiThreeLeaves size={28} className="icon_food mt-1"></GiThreeLeaves>
    ),
    lupin: <GiThreeLeaves size={28} className="icon_food mt-1"></GiThreeLeaves>,
    sennep: <GiMasonJar size={28} className="icon_food mt-1"></GiMasonJar>,
    sesamfrø: <GiPlantSeed size={28} className="icon_food mt-1"></GiPlantSeed>,
    "svoveloksid og sulfitter": (
      <GiMolecule size={28} className="icon_food mt-1"></GiMolecule>
    ),
    bløtdyr: <GiSnail size={28} className="icon_food mt-1"></GiSnail>,
  };

  return allergyMap[type] || null;
}

const MenuOrder = () => {
  //Id from url
  let menuId = useParams();
  const [loading, setLoading] = useState(false);

  const functions = getFunctions(app);
  const verifyPhoneNumber = httpsCallable(functions, "verifyNumber");

  registerLocale("nb", nb);

  //Menu details
  let data: (DocumentData | undefined)[] = [];
  const [warmDishes, setWarmDishes] = useState([]);
  const [coldDishes, setColdDishes] = useState([]);
  const [desert, setDesert] = useState([]);
  const [sides, setSides] = useState([]);
  const [extras, setExtras] = useState([]);

  const [allergies, setAllergies] = useState([]);

  const [menuTitle, setMenuTitle] = useState("");
  const [menuImg, setMenuImg] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [suppliersId, setSuppliersId] = useState();
  const [suppliers, setSuppliers] = useState([]);
  const [comment, setComment] = useState("");
  const [supplier, setSupplier] = useState();
  const [description, setDescription] = useState();
  const [priceOfDelivery, setPriceOfDelivery] = useState();

  //Number of persons
  const [numberOfPersons, setNumberOfPersons] = useState(0);

  //Phonenumber
  const [phonenumber, setPhonenumber] = useState();
  var number = new RegExp(/^[0-9]{8}$/i);

  //Date and time
  const [date, setDate] = useState();
  const [time, setTime] = useState(); //.getHours() to get time as number

  //Delirvery method
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [showDeliveryChoices, setShowDeliveryChoices] = useState(false);
  const [showChoseAdressForDelivery, setShowChoseAdressForDelivery] =
    useState(false);
  const handleCloseDeliveryChoices = () => setShowDeliveryChoices(false);
  const [deliveryCoordinates, setDeliveryCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [deliveryAdress, setDeliveryAdress] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [distanceToDelivery, setDistanceToDelivery] = useState();
  const [showIfDeliveryIsPossible, setShowIfDeliveryIsPossible] =
    useState(false);
  const [latSupplier, setLatSupplier] = useState();
  const [longSupplier, setLongSupplier] = useState();
  const [supplierAdress, setSupplierAddress] = useState();
  const [supplierTown, setSupplierTown] = useState();
  const [supplierZip, setSupplierZip] = useState();

  //Navigation
  const navigation = useNavigate();
  let routeChange = (path: string) => {
    navigation(path);
  };

  //Validation of form
  const [areFieldsFilled, setAreFieldsFilled] = useState("disabled");

  //Total price of order
  const [totalPrice, setTotalPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [extrasPrice, setExtrasPrice] = useState(0);
  const [chosenExtras, setChosenExtras] = useState([]);

  const [pricePerPerson, setPricePerPerson] = useState(0);

  //Complete order
  const [terms, setTerms] = useState();
  const [showCompleteOrder, setShowCompleteOrder] = useState(false);
  const handleCompleteOrder = () => setShowCompleteOrder(false);

  //Rabattkode
  const [rabattkoder, setRabattkoder] = useState<DocumentData[]>([]);
  const [rabattkodeVisibility, setRabattkodeVisibility] = useState(false);
  const [inputRabattkode, setInputRabattkode] = useState();
  const [rabattFeilKode, setRabattFeilKode] = useState("");
  const [rabattertVerdi, setRabattertVerdi] = useState(0);
  const [isValid, setIsValid] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");

  //Check verification code
  const [verifyCode, setVerifyCode] = useState();
  const [verifyInputCode, setVerifyInputCode] = useState();
  const [validatePhonenumberFalse, setValidatePhonenumberFalse] =
    useState(false);

  let threeDaysAhead = new Date();
  threeDaysAhead.setDate(threeDaysAhead.getDate() + 3);

  //Modal
  const [validatePhonenumberModal, setValidatePhonenumberModal] =
    useState(false);
  const handleValidatePhonenumberModal = () =>
    setValidatePhonenumberModal(false);
  const [showChosenAdressForPickup, setShowChosenAdressForPickup] =
    useState(false);

  useEffect(() => {
    if (suppliersId) {
      fetchSuppliersData();
    }
  }, [suppliersId]);

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchRabattkode();
    fetchSupplier();
  }, []);

  useEffect(() => {
    if (numberOfPersons !== 0) {
      setPricePerPerson(startPrice * numberOfPersons);
    }
  }, [numberOfPersons]);

  useEffect(() => {
    if (
      date &&
      time &&
      number.test(phonenumber) &&
      numberOfPersons >= 10 &&
      deliveryMethod
    ) {
      setAreFieldsFilled("");
    } else {
      setAreFieldsFilled("disabled");
    }
  }, [date, time, phonenumber, numberOfPersons, deliveryMethod]);

  useEffect(() => {
    totalPriceOfOrder();
  }, [startPrice, extrasPrice, pricePerPerson, deliveryPrice, rabattertVerdi]);

  const [extraAddons, setExtraAddons] = useState([]);

  const [matchedAddons, setMatchedAddons] = useState([]);

  async function fetchSupplier() {
    const supplierId = menuId.supplierId;
    const docRef = doc(db, "suppliers", supplierId);
    try {
      const docSnap = await getDoc(docRef);
      const {
        menus,
        deliveryCost,
        name,
        lat,
        long,
        address,
        zip,
        city,
        addons,
      } = docSnap.data();

      if (!deliveryCost) {
        setDeliveryMethod("Henting");
      } else {
        setPriceOfDelivery(deliveryCost);
      }

      setSuppliersId(supplierId);
      setSupplier(name);
      setLatSupplier(lat);
      setLongSupplier(long);
      setSupplierAddress(address);
      setSupplierZip(zip);
      setSupplierTown(city);

      if (addons) {
        setExtraAddons(addons);
      }
      const matchingMenu = menus.find((item) => item.id === menuId.menuName);

      if (matchingMenu) {
        setDescription(matchingMenu.description);
        setMenuTitle(matchingMenu.name);
        setStartPrice(matchingMenu.price);
        setMenuImg(matchingMenu.img);
        filterCoursesByType(matchingMenu.courses);
        if (matchingMenu.allergens) {
          setAllergies(matchingMenu.allergens);
        }

        // Match addons for this menu
        if (addons && matchingMenu.addonArray) {
          const matchedAddons = matchingMenu.addonArray.map((addon) =>
            addons.find((extra) => extra.id === addon)
          );
          setMatchedAddons(matchedAddons);
        }
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  function filterCoursesByType(courses) {
    const courseTypes = ["hot", "cold", "dessert", "acc", "choose"];
    const setters = [
      setWarmDishes,
      setColdDishes,
      setDesert,
      setSides,
      setExtras,
    ];

    courseTypes.forEach((type, index) => {
      const filteredCourses = courses.filter((course) => course.type === type);
      setters[index](filteredCourses);
    });
  }

  const fetchSuppliersData = async () => {
    if (suppliersId) {
      for (let i = 0; i < suppliersId.length; i++) {
        let docRef = doc(db, "suppliers", suppliersId[i]);
        try {
          const doc = await getDoc(docRef);
          suppliers.push({ id: suppliersId[i], ...doc.data() });
          setSuppliers(suppliers);
        } catch (e) {
          console.log("Error getting document:", e);
        }
      }
    }
  };

  const fetchData = async () => {
    let name = menuId.menuName;
    if (name !== null) {
      let docRef = doc(db, "menus", name);
      try {
        const doc = await getDoc(docRef);
        data.push(doc.data());
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchRabattkode = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "discount"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setRabattkoder([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function totalPriceOfOrder() {
    let totalprice =
      pricePerPerson + extrasPrice + deliveryPrice - rabattertVerdi;
    return setTotalPrice(totalprice);
  }

  function calculateExtrasPrice(name: string, price: number, count: number) {
    let extrasPrice = 0;

    const updatedChosenExtras = [...chosenExtras];
    const existingExtraIndex = updatedChosenExtras.findIndex(
      (chosenExtra) => chosenExtra.name === name
    );

    if (existingExtraIndex !== -1) {
      updatedChosenExtras[existingExtraIndex].count = count;
    } else {
      updatedChosenExtras.push({
        name: name,
        count: count,
        price: price,
      });
    }

    setChosenExtras(updatedChosenExtras);

    updatedChosenExtras.forEach((extra) => {
      extrasPrice += extra.count * extra.price;
    });

    setExtrasPrice(extrasPrice);
  }

  async function handleAddress(e: any) {
    setDeliveryAdress(e);
    const results = await geocodeByAddress(e);
    const latLng = await getLatLng(results[0]);
    setDeliveryCoordinates(latLng);
  }

  const checkVerifyCode = () => {
    if (verifyCode === verifyInputCode) {
      setValidatePhonenumberModal(false);
      setLoading(true);
      handleOrder(
        suppliersId,
        menuTitle,
        numberOfPersons,
        date,
        time,
        totalPrice,
        deliveryMethod,
        phonenumber,
        extrasPrice,
        deliveryAdress,
        comment
      );
    } else {
      setValidatePhonenumberModal(false);
      setValidatePhonenumberFalse(true);
    }
  };

  async function handleOrder(
    supplierId: string,
    menuTitle: string,
    numberOfPersons: number,
    date: string,
    time: string,
    totalPrice: number,
    deliveryMethod: string,
    phonenumber: string,
    extrasPrice: number,
    deliveryAdress: string,
    comment: string
  ) {
    await addDoc(collection(db, "sales"), {
      created: new Date(),
      delivery: {
        method: deliveryMethod,
        adress: deliveryAdress,
        time: time,
        date: date,
      },
      supplierId: supplierId,
      menuName: menuTitle,
      peopleCount: numberOfPersons,
      phonenumber: phonenumber,
      chosenExtras: chosenExtras,
      extrasPrice: extrasPrice,
      totalPrice: totalPrice,
      paymentStatus: "Ikke betalt",
      status: "Ikke bekreftet",
      commentCustomer: [
        {
          comment: comment,
          date: new Date(),
          writer: "customer",
        },
      ],
      commentSupplier: "",
    })
      .then(function (docRef) {
        routeChange(`/order/${docRef.id}`);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  function checkDistanceToPickup(lat: number, lon: number) {
    const distanceToSupplier = distanceTo(lat, lon, latSupplier, longSupplier);
    setShowChoseAdressForDelivery(false);
    setShowIfDeliveryIsPossible(true);

    if (distanceToSupplier > 30) {
      setDeliveryMethod("Henting");
      setDeliveryPrice(0);
    } else {
      setDeliveryMethod("Levering");
      setDeliveryPrice(priceOfDelivery);
    }
    setDistanceToDelivery(distanceToSupplier);
  }

  function verifyPhonenumber(number: string) {
    let phoneNumber = number;
    let data = {
      phonenumber: phoneNumber,
    };
    verifyPhoneNumber(data).then((response) => {
      setVerifyCode(response.data);
    });
  }

  function placeOrder() {
    if (!terms) {
      return setTerms(false);
    } else {
      setTerms(true);
      setShowCompleteOrder(false);
      setValidatePhonenumberModal(true);
      verifyPhonenumber(phonenumber);
    }
  }

  function checkRabattkode() {
    if (!inputRabattkode) {
      setRabattertVerdi(0);
      setIsValid(null);
      setValidationMessage("");
      return;
    }

    let isValidCode = false;
    let value = startPrice * numberOfPersons + extrasPrice;

    rabattkoder.map((rabattkode) => {
      if (inputRabattkode === rabattkode.id) {
        isValidCode = true;
        if (rabattkode.value < 1 && rabattkode.valid > 0) {
          setRabattFeilKode("");
          setRabattertVerdi(Math.ceil(rabattkode.value * value));
        } else if (rabattkode.value > 1 && rabattkode.valid > 0) {
          setRabattFeilKode("");
          setRabattertVerdi(rabattkode.value);
        } else {
          setRabattFeilKode("Ugyldig rabattkode");
        }
      }
    });

    setIsValid(isValidCode);

    if (isValidCode) {
      setValidationMessage("");
    } else {
      setValidationMessage(
        "Ugyldig kode. Husk forskjell på store og små bokstaver."
      );
      setTimeout(() => {
        setIsValid(null);
        setValidationMessage("");
      }, 3000); // Duration of the invalid state animation in milliseconds
    }
  }

  return (
    <>
      {!loading && data !== [] ? (
        <>
          <Container className="lg:px-20">
            <Row>
              <Col lg={{ span: 7 }}>
                <h1 className="font-montserrat lg:py-10 py-6 text-4xl">
                  {capitalizeFirstLetter(menuTitle)}
                </h1>
                <div className="pb-8 lg:w-11/12 font-montserrat tracking-wide">
                  {description}
                </div>
                <img className="rounded-3xl lg:w-11/12" src={menuImg} alt="" />
                <Card className="my-8 p-4 lg:w-11/12 !rounded-3xl !shadow-lg !border-none">
                  <>
                    {warmDishes.length > 0 ? (
                      <div className="py-2 text-lg">
                        VARME RETTER
                        {warmDishes.map((dish) => {
                          return (
                            <Row>
                              <div className="flex g-2">
                                <Col md={{ span: 12 }}>
                                  <h6 className="font-montserrat text-md flex ">
                                    <GiCookingPot
                                      size={28}
                                      className="icon_food mt-1"
                                    />
                                    {/*  <FontAwesomeIcon
                                      icon={myIcons[icon]}
                                      className="icon_food"
                                      size="xl"
                                    /> */}

                                    <div className="pt-2 pl-2">{dish.name}</div>
                                  </h6>
                                </Col>
                              </div>
                            </Row>
                          );
                        })}{" "}
                      </div>
                    ) : null}
                    {coldDishes.length > 0 ? (
                      <div className="py-3 text-lg">
                        KALDE RETTER
                        {coldDishes.map((dish) => {
                          return (
                            <Row>
                              <div className="flex g-2">
                                <Col md={{ span: 12 }}>
                                  <h6 className="font-montserrat text-md flex">
                                    <GiCookingPot
                                      size={28}
                                      className="icon_food mt-1"
                                    />{" "}
                                    <div className="pt-2 pl-2">{dish.name}</div>
                                  </h6>
                                </Col>
                              </div>
                            </Row>
                          );
                        })}{" "}
                      </div>
                    ) : null}
                    {desert.length > 0 ? (
                      <div className="py-3 text-lg">
                        DESSERT
                        {desert.map((dish) => {
                          return (
                            <Row>
                              <div className="flex g-2">
                                <Col md={{ span: 12 }}>
                                  <h6 className="font-montserrat text-md flex">
                                    <GiCookingPot
                                      size={28}
                                      className="icon_food mt-1"
                                    />{" "}
                                    <div className="pt-2 pl-2">{dish.name}</div>
                                  </h6>
                                </Col>
                              </div>
                            </Row>
                          );
                        })}{" "}
                      </div>
                    ) : null}
                    {sides.length > 0 ? (
                      <div className="py-3 text-lg">
                        TILBEHØR
                        {sides.map((dish) => {
                          return (
                            <Row>
                              <div className="flex g-2">
                                <Col md={{ span: 12 }}>
                                  <h6 className="font-montserrat text-md flex">
                                    <GiCookingPot
                                      size={28}
                                      className="icon_food mt-1"
                                    />{" "}
                                    <div className="pt-2 pl-2 ">
                                      {dish.name}
                                    </div>
                                  </h6>
                                </Col>
                              </div>
                            </Row>
                          );
                        })}{" "}
                      </div>
                    ) : null}
                    {allergies.length > 0 ? (
                      <div className="py-3 text-lg">
                        ALLERGENER
                        {allergies.map((dish) => {
                          return (
                            <Row>
                              <div className="flex g-2">
                                <Col md={{ span: 12 }}>
                                  <h6 className="font-montserrat text-md flex">
                                    {checkAllergy(dish)}{" "}
                                    <div className="pt-2 pl-2 ">{dish}</div>
                                  </h6>
                                </Col>
                              </div>
                            </Row>
                          );
                        })}{" "}
                      </div>
                    ) : null}
                  </>
                </Card>
              </Col>
              <Col lg={{ span: 5 }} className="mb-20">
                <Row>
                  <Col lg={{ span: 8 }} xs={{ span: 8 }}>
                    <h4 className="font-montserrat lg:pt-16 pt-8 pl-2 !text-xl">
                      Bestill nå
                    </h4>
                    {pricePerPerson !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        {menuTitle} ({numberOfPersons} x {startPrice},-)
                      </h4>
                    ) : null}
                    {extrasPrice !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        Tilleggsretter
                      </h4>
                    ) : null}
                    {deliveryPrice !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        Levering
                      </h4>
                    ) : null}
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 4 }}>
                    <h4 className="font-montserrat lg:pt-16 pt-8 pl-2 flex !text-2xl">
                      {totalPrice > 0 ? (
                        <div className="!text-2xl">{totalPrice} ,-</div>
                      ) : (
                        <>
                          <div className="text-sm pr-2 pt-1 !text-xl">fra</div>{" "}
                          <div className="!text-2xl">{startPrice},-</div>
                        </>
                      )}
                    </h4>
                    {pricePerPerson !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        {numberOfPersons * startPrice} ,-
                      </h4>
                    ) : null}
                    {extrasPrice !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        {extrasPrice} ,-
                      </h4>
                    ) : null}
                    {deliveryPrice !== 0 ? (
                      <h4 className="font-montserrat pt-2  pl-2 !text-sm !text-[#808080]">
                        {deliveryPrice} ,-
                      </h4>
                    ) : null}
                  </Col>
                </Row>
                <hr
                  style={{
                    display: "block",
                    height: "2px",
                    border: "0",
                    borderTop: "1px solid #ccc",
                    width: "90%",

                    marginTop: "15px",
                    padding: "0",
                  }}
                />
                <Row>
                  <Col md={{ span: 6 }}>
                    <Card
                      className={`text-black bg-white text-left ml-2 mt-3 shadow-md`} //border-${inputStyleRentPeriode}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1">
                        <div className="text-xs text-light pb-3 pl-2 text-black">
                          DATO{" "}
                        </div>
                        <DatePicker
                          selected={date}
                          minDate={threeDaysAhead}
                          locale="nb"
                          className="w-24 ml-2"
                          onChange={(date: Date) => setDate(date)}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={{ span: 4 }} className="ml-2">
                    <Card
                      className={`text-black bg-white text-left mt-3 mr-2 lg:mr-0 shadow-md`} //border-${inputStyleRentPeriode}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1">
                        <div className="text-xs text-light pb-3 pl-2 text-black">
                          TIDSPUNKT{" "}
                        </div>
                        <DatePicker
                          selected={time}
                          onChange={(time) => setTime(time)}
                          // filterTime={filterPassedTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          locale="nb"
                          timeCaption="Time"
                          className="w-16 ml-2"
                          dateFormat="HH:mm"
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card
                      className={`text-black bg-white text-left lg:w-10/12 ml-2 mt-3 shadow-md`} //border-${inputStyleRentPeriode}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1">
                        <div className="text-xs text-light pb-3 text-black pl-2">
                          ANTALL PERSONER{" "}
                        </div>
                        <input
                          placeholder="Minst 10"
                          type="number"
                          className="h-6 ml-2"
                          min="0"
                          onChange={(e) => setNumberOfPersons(e.target.value)}
                        />
                      </Card.Body>
                    </Card>
                    {numberOfPersons !== "" &&
                    numberOfPersons !== 0 &&
                    numberOfPersons < 10 ? (
                      <div className="pl-2 pt-1 font-normal text-sm tracking-wider text-red-600">
                        Antall personer må være minst 10
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!priceOfDelivery ? (
                      <Card
                        className={`text-black bg-white text-left lg:w-10/12 ml-2 mt-3 shadow-md`} //border-${inputStyleRentPeriode}
                      >
                        <Card.Body className=" flex ">
                          <Button
                            variant="secondary"
                            className={`text-black bg-white text-left !border-none !text-left p-0 disabled`} //border-${inputStyleDelivery}
                          >
                            <div className="text-xs text-light pb-3 text-black ">
                              Mat må hentes hos {supplier}
                            </div>
                            <strong>
                              {supplierAdress}, {supplierZip} {supplierTown}{" "}
                            </strong>{" "}
                          </Button>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Card
                        className={`text-black bg-white text-left lg:w-10/12 ml-2 mt-3 shadow-md`} //border-${inputStyleRentPeriode}
                      >
                        <Card.Body className=" flex ">
                          <Button
                            variant="secondary"
                            className={`text-black bg-white text-left !border-none !text-left p-0 `} //border-${inputStyleDelivery}
                            onClick={(e) => setShowDeliveryChoices(true)}
                          >
                            <div className="text-xs text-light pb-3 text-black ">
                              LEVERING ELLER HENTING
                            </div>
                            {deliveryMethod ? (
                              <div>
                                {" "}
                                <strong>{deliveryMethod} </strong>{" "}
                                {deliveryMethod === "Levering" &&
                                deliveryAdress ? (
                                  <>
                                    {" "}
                                    til <strong>{deliveryAdress}</strong>
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <div>
                                Trykk for å velge <strong>henting</strong> eller{" "}
                                <strong>levering</strong>
                              </div>
                            )}
                          </Button>
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card
                      className={`text-black bg-white text-left lg:w-10/12 ml-2 mt-3 shadow-md `} //border-${inputStylePhone}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <div className="text-xs text-light pb-2 text-black pl-2">
                          DITT TELEFONNUMMER{" "}
                        </div>
                        <input
                          className="w-100  pb-2 pl-2"
                          value={phonenumber}
                          onChange={(e) => setPhonenumber(e.target.value)}
                          placeholder="Skriv inn ditt telefonnummer"
                        ></input>
                      </Card.Body>
                    </Card>
                    {phonenumber && !number.test(phonenumber) ? (
                      <div className="pl-2 pt-1 font-normal text-sm tracking-wider text-red-600">
                        Du må fylle inn telefonnummer, eksempel:{" "}
                        <em>12345678</em>
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="secondary"
                      className={`!bg-button text-white tracking-wider lg:w-10/12 ml-2 !font-medium mt-8 rounded-md pt-3 pb-3 focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform !border-button ${areFieldsFilled}`}
                      onClick={() => {
                        setShowCompleteOrder(true);
                      }}
                    >
                      NESTE
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <>
                    <div
                      className="ml-2 w-8/12 pt-4 font-light text-sm tracking-wider pb-2 cursor-pointer "
                      onClick={(e) =>
                        setRabattkodeVisibility(!rabattkodeVisibility)
                      }
                    >
                      Har du en rabattkode?
                    </div>
                    <hr
                      style={{
                        display: "block",
                        height: "2px",
                        border: "0",
                        borderTop: "1px solid #111",
                        width: "80%",
                        marginTop: "0em",
                        padding: "0",
                        marginLeft: "1rem",
                      }}
                    />
                  </>
                </Row>
                {rabattkodeVisibility ? (
                  <>
                    <div className="ml-2 mt-2 font-light text-sm tracking-wider ">
                      Legg til etter antall personer
                    </div>
                    <Row>
                      <div className="flex ml-2 mt-1">
                        <Card className="w-6/12">
                          <Card.Body>
                            <input
                              value={inputRabattkode}
                              onChange={(e) => {
                                setInputRabattkode(e.target.value);
                                if (e.target.value === "") {
                                  setIsValid(null);
                                  setValidationMessage("");
                                }
                              }}
                              className="w-32"
                              placeholder="Skriv rabattkode"
                            />
                          </Card.Body>
                        </Card>

                        <Button
                          variant={isValid ? "success" : "danger"}
                          className={`text-white rounded-md ml-3 w-3/12 ${
                            isValid === null
                              ? "!bg-button !border-button !hover:bg-button !hover:border-button !focus:bg-button !focus:border-button !active:bg-button !active:border-button"
                              : isValid
                              ? "bg-success border-success hover:bg-success hover:border-success focus:bg-success focus:border-success active:bg-success active:border-success"
                              : "bg-danger border-danger hover:bg-danger hover:border-danger focus:bg-danger focus:border-danger active:bg-danger active:border-danger"
                          }`}
                          onClick={checkRabattkode}
                        >
                          {isValid === null ? (
                            "Aktiver"
                          ) : isValid ? (
                            <div className="flex items-center ml-11">
                              <IoCheckmark />
                            </div>
                          ) : (
                            <div className="flex items-center ml-11">
                              <IoClose />
                            </div>
                          )}
                        </Button>
                      </div>

                      {validationMessage && (
                        <div className="text-red-500 ml-2 mt-1">
                          {validationMessage}
                        </div>
                      )}
                    </Row>
                  </>
                ) : null}
                {rabattFeilKode !== "" && rabattkodeVisibility ? (
                  <div className="ml-16 font-medium text-sm tracking-wider pb-2 pt-1 text-red">
                    {rabattFeilKode}
                  </div>
                ) : null}

                <Row>
                  <Col>
                    <div className="flex mt-6">
                      <GiCookingPot size={36} className="icon_food ml-2 mr-3" />
                      <div className="mt-2 font-montserrat">
                        {" "}
                        Tilberedt av <strong>{supplier}</strong>{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Container className="mb-10">
              <Row>
                {matchedAddons.length > 0 ? (
                  <>
                    <div className="pt-3 pb-2 text-2xl font-montserrat">
                      TILLEGGSRETTER
                    </div>
                    {matchedAddons.map((dish) => {
                      return (
                        <Col lg={{ span: 3 }} className="">
                          <AditionalDishCard
                            img={dish.img}
                            name={dish.name}
                            key={dish.id}
                            description={dish.description}
                            price={dish.price}
                            max={10}
                            onChange={(e) => {
                              const count = parseInt(e.target.value) || 0;
                              calculateExtrasPrice(
                                dish.name,
                                dish.price,
                                count
                              );
                            }}
                          />{" "}
                        </Col>
                      );
                    })}{" "}
                  </>
                ) : null}
              </Row>
            </Container>
          </Container>

          {/* Modaler */}

          {/** Modal hvis validering av nummer ikke var korrekt */}
          <ModalComponent
            show={validatePhonenumberFalse}
            title="Ikke korrekt verifiseringskode"
            hide={() => setValidatePhonenumberFalse(false)}
            footer={
              <Button
                variant="secondary"
                className="text-white !bg-[#24292e] text-left ml-16 mt-3"
                onClick={() => setValidatePhonenumberFalse(false)}
              >
                Lukk
              </Button>
            }
            body={
              <>
                Kode som ble skrevet inn stemte ikke overens med kode som ble
                sendt på SMS, vennligst prøv igjen.
              </>
            }
          />

          {/** Modal for å vertifisere mobilnummer før man fullfører bestilling */}
          <ModalComponent
            show={validatePhonenumberModal}
            title="Verifisering"
            hide={handleValidatePhonenumberModal}
            footer={
              <Button
                variant="secondary"
                className="text-white bg-[#24292e] text-left ml-16 mt-3"
                onClick={handleValidatePhonenumberModal}
              >
                Lukk
              </Button>
            }
            body={
              <>
                <InputGroup>
                  <Container className="mt-6 mb-6">
                    <Row>
                      <div className="text-md tracking-wider font-weight-400">
                        Vi sendte nettopp en kode til{" "}
                        <strong>{phonenumber}</strong>:{" "}
                        <em>(vent inntil 30 sek)</em>
                      </div>
                      <Col
                        lg={{ span: 8, order: 0 }}
                        xs={{ span: 12 }}
                        className="mt-4"
                      >
                        <FormControl
                          placeholder="Kode (6 siffer)"
                          aria-describedby="verify-code"
                          onChange={(e) =>
                            setVerifyInputCode(Number(e.target.value))
                          }
                        />
                      </Col>
                      <Col
                        lg={{ span: 3, order: 0 }}
                        xs={{ span: 12 }}
                        className="mt-4"
                      >
                        <Button
                          className="tracking-wide w-100 !bg-button !border-button !hover:bg-button !hover:border-button !focus:bg-button !focus:border-button !active:bg-button !active:border-button"
                          onClick={checkVerifyCode}
                        >
                          Bekreft
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </InputGroup>
              </>
            }
          />

          {/** Modal for å vise om levering er mulig */}
          <ModalComponent
            show={showIfDeliveryIsPossible}
            hide={(e) => setShowIfDeliveryIsPossible(false)}
            title="Levering"
            footer={
              <Button
                variant="secondary"
                className="text-white bg-[#24292e] text-left ml-16 mt-3"
                onClick={(e) => setShowIfDeliveryIsPossible(false)}
              >
                Lukk
              </Button>
            }
            body={
              <>
                <div className="pt-6 mb-4 text-md">
                  Din adresse er {distanceToDelivery} km unna {supplier}
                </div>
                {distanceToDelivery < 30 ? (
                  <>
                    <h3 className="">
                      Vi kan levere til {deliveryAdress} for{" "}
                      <strong>{deliveryPrice},-</strong>
                    </h3>
                    <Button
                      variant="secondary"
                      className="!text-footer bg-white text-left lg:ml-4 w-11/12 mt-3 mb-8 !border-footer"
                      onClick={() => {
                        setDeliveryMethod("Levering");
                        setShowIfDeliveryIsPossible(false);
                      }}
                    >
                      <div className="pb-2 pt-2 !text-footer">
                        <strong>Jeg ønsker levering</strong>.
                      </div>
                    </Button>
                    <h2
                      style={{
                        width: "100%",
                        textAlign: "center",
                        borderBottom: "1px solid #d3d3d3",
                        lineHeight: "0.1em",
                        margin: "10px 0 20px",
                      }}
                    >
                      <span
                        style={{
                          padding: "0 10px",
                          background: "#fff",
                          color: "#808080",
                        }}
                      >
                        ELLER
                      </span>
                    </h2>
                    <Button
                      variant="secondary"
                      className="!text-footer bg-white text-left w-11/12 lg:ml-4 mt-3 mb-8 !border-footer"
                      onClick={() => {
                        setDeliveryMethod("Henting");
                        setShowIfDeliveryIsPossible(false);
                      }}
                    >
                      <div className="pb-2 pt-2 !text-footer">
                        <strong>Jeg vil heller hente selv</strong>.
                      </div>
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                      <div className="text-red-500">
                        <strong>
                          <i className="fas fa-exclamation-triangle" /> Levering
                          er ikke mulig fordi du er for langt unna vårt nærmeste
                          lager.
                        </strong>
                      </div>
                    </div>
                  </>
                )}
              </>
            }
          />

          {/** Modal for å velge hentested */}
          <ModalComponent
            show={showChosenAdressForPickup}
            hide={(e) => setShowChosenAdressForPickup(false)}
            title="Hente selv"
            footer={
              <Button
                variant="secondary"
                className="text-white bg-[#24292e] text-left ml-16 mt-3"
                onClick={(e) => setShowChosenAdressForPickup(false)}
              >
                Lukk
              </Button>
            }
            body={
              <>
                <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                  Hvor ønsker du å hente din bestilling? Velg en av addressene
                  under:
                </div>
                {suppliers.map((suppliers) => {
                  return (
                    <Button
                      variant="secondary"
                      className="text-black bg-white text-left w-10/12 ml-8 mt-3"
                      onClick={() => {
                        setSupplierId(suppliers.id);
                        setShowChosenAdressForPickup(false);
                      }}
                    >
                      <Row>
                        <Col sm={8}>
                          <div className="pb-2 pt-2 text-black">
                            <strong>{suppliers.name}</strong> -{" "}
                            {suppliers.address}, {suppliers.city}
                          </div>
                        </Col>
                      </Row>
                    </Button>
                  );
                })}
              </>
            }
          />

          {/** Modal for å skrive inn ønsket leveringsadresse */}
          <ModalComponent
            show={showChoseAdressForDelivery}
            hide={(e) => setShowChoseAdressForDelivery(false)}
            title="Levering"
            footer={
              <Button
                variant="secondary"
                className="text-white bg-[#24292e] text-left ml-16 mt-3"
                onClick={(e) => setShowChoseAdressForDelivery(false)}
              >
                Lukk
              </Button>
            }
            body={
              <>
                <div className="lg:pl-8 lg:pr-6 pt-6 mb-2 text-sm">
                  For å gi deg en leveringspris må vi vite hvor du ønsker maten
                  levert. Skriv inn adressen i feltet under:
                </div>
                <Row className="lg:pl-8">
                  <Col xs={8}>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        deliveryAdress,
                        onChange: (deliveryAdress: any) => {
                          handleAddress(deliveryAdress.label);
                        },
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button
                      className="!bg-button !border-button text-white tracking-wider px-8"
                      onClick={() => {
                        checkDistanceToPickup(
                          deliveryCoordinates.lat,
                          deliveryCoordinates.lng
                        );
                      }}
                    >
                      Søk
                    </Button>
                  </Col>
                </Row>
              </>
            }
          />

          {/** Modal for å velge henting eller levering */}
          <ModalComponent
            show={showDeliveryChoices}
            hide={handleCloseDeliveryChoices}
            title="Velg levering eller henting"
            footer={
              <Button
                variant="secondary"
                className="text-white !bg-[#D6CBC1] !border-[#D6CBC1] text-left ml-16 mt-3"
                onClick={handleCloseDeliveryChoices}
              >
                Lukk
              </Button>
            }
            body={
              <>
                <Button
                  variant="secondary"
                  className="bg-white text-left w-11/12  ml-4  mt-3 mb-6 !shadow-lg !border-[#D6CBC1]"
                  onClick={() => {
                    setDeliveryMethod("Henting");
                    setShowDeliveryChoices(false);
                  }}
                >
                  <div className="pb-2 pt-2 text-black">
                    Jeg ønsker å <strong>hente</strong> maten selv.
                  </div>
                </Button>
                <h4
                  style={{
                    width: "100%",
                    textAlign: "center",
                    borderBottom: "1px solid #d3d3d3",
                    lineHeight: "0.1em",
                    margin: "10px 0 20px",
                  }}
                >
                  <span
                    style={{
                      padding: "0 10px",
                      background: "#fff",
                      color: "#808080",
                      fontSize: "1.2rem",
                    }}
                  >
                    ELLER
                  </span>
                </h4>
                <Button
                  variant="secondary"
                  className="text-black bg-white text-left w-11/12  ml-4 mt-3 mb-6 !shadow-lg !border-[#D6CBC1]"
                  onClick={() => {
                    setShowChoseAdressForDelivery(true);
                    setShowDeliveryChoices(false);
                  }}
                >
                  <div className="pb-2 pt-2 text-black">
                    Jeg ønsker å få maten <strong>levert</strong>.
                  </div>
                </Button>
              </>
            }
          />
          {/** Modal for å fullføre bestilling. Velger om man ønsker forsikring eller ikke */}
          <ModalComponent
            show={showCompleteOrder}
            hide={handleCompleteOrder}
            title="Fullfør bestilling"
            footer={
              <>
                <Button
                  variant="secondary"
                  className={`text-white !bg-button text-center ml-16 mt-3 !border-button `}
                  onClick={placeOrder}
                >
                  BESTILL NÅ
                </Button>
              </>
            }
            body={
              <>
                <div className="text-2xl tracking-wider pb-2 pt-2">Pris</div>
                <Card className="text-black bg-white text-left w-12/12 !border-t-1 !border-r-1 !border-b-0 !border-l-1 !rounded-none">
                  <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                    <Row className="mr-4 ml-2">
                      <Col sm={9} xs={8}>
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                          {menuTitle} x {numberOfPersons}
                        </div>
                      </Col>
                      <Col sm={3} xs={4}>
                        <div className="text-sm tracking-wider pb-2 pt-2 pl-2 float-right text-black">
                          {startPrice * numberOfPersons},-
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {extrasPrice !== 0 ? (
                  <div>
                    <Card className="text-black bg-white text-left w-12/12 !border-t-1 !border-r-1 !border-b-0 !border-l-1 !rounded-none">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <Row className="mr-4 ml-2">
                          <Col sm={9} xs={8}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                              Tilleggsretter
                              {chosenExtras.map((extra: any) => {
                                return (
                                  <>
                                    {extra.count > 0 ? (
                                      <div className="pt-2 pl-4 italic">
                                        {extra.name}, {extra.count} stykk
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                            </div>
                          </Col>
                          <Col sm={3} xs={4}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 float-right text-black">
                              {extrasPrice},-
                              {chosenExtras.map((extra: any) => {
                                return (
                                  <>
                                    {extra.count > 0 ? (
                                      <div className="pt-2 italic text-sm">
                                        {extra.price * extra.count},-
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                ) : null}
                {rabattertVerdi !== 0 ? (
                  <div>
                    <Card className="text-black bg-white text-left w-12/12 !border-t-1 !border-r-1 !border-b-0 !border-l-1 !rounded-none">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <Row className="mr-4 ml-2">
                          <Col sm={9} xs={8}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                              Rabatt
                            </div>
                          </Col>
                          <Col sm={3} xs={4}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 float-right text-black">
                              {rabattertVerdi},-
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                ) : null}
                {deliveryPrice !== 0 ? (
                  <div>
                    <Card className="text-black bg-white text-left w-12/12 !border-t-1 !border-r-1 !border-b-0 !border-l-1 !rounded-none">
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <Row className="mr-4 ml-2">
                          <Col sm={9} xs={8}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                              Leveringspris
                            </div>
                          </Col>
                          <Col sm={3} xs={4}>
                            <div className="text-sm tracking-wider pb-2 pt-2 pl-2 float-right text-black">
                              {deliveryPrice},-
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                ) : null}

                <Card className="text-black bg-white text-left w-12/12 border-t-4 border-[#24292e] !rounded-none">
                  <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                    <Row className="mr-4 ml-2">
                      <Col sm={9} xs={8}>
                        <div className="text-md tracking-wider pb-2 pt-2 pl-2 text-black">
                          <div className=" font-bold pb-1 ">
                            Totalt å betale
                          </div>
                          <div className="text-xs">
                            (Totalsum inkludert mva)
                          </div>
                        </div>
                      </Col>
                      <Col sm={3} xs={4}>
                        <div className="text-md tracking-wider pb-2 pt-3 pl-2 float-right text-black font-bold">
                          {totalPrice},-
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <div className="text-md tracking-wider pb-2 pt-4">
                  Kommentar til bestilling
                </div>
                <input
                  type="text"
                  className="w-full h-10 border border-gray-400 rounded px-3 py-2 outline-none text-gray-600"
                  onChange={(e) => setComment(e.target.value)}
                />

                <div className="mt-3">
                  <Form>
                    <Form.Check
                      checked={terms}
                      onChange={(e) => setTerms(e.target.checked)}
                      type="checkbox"
                      id="default-checkbox"
                      label={
                        <div>
                          <span className="text-sm tracking-wider pb-2">
                            Ved å krysse av godtar du Kasserolle AS sine {""}
                            <a
                              href={"https://kasserolle.no/betingelser"}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline"
                            >
                              Kjøpsbetingelser
                            </a>
                            <span className="text-red">*</span>
                          </span>
                          {terms === false ? (
                            <div className="pt-1 font-normal text-sm tracking-wider text-red-600">
                              For å legge inn en bestilling må du godta
                              Kjøpsbetingelsene
                            </div>
                          ) : null}
                        </div>
                      }
                    />
                  </Form>
                </div>
              </>
            }
          />
        </>
      ) : (
        <>
          <div className="flex justify-center items-center ml-9 mt-20">
            <svg
              className="w-36 h-236 animate-bounce"
              fill="none"
              viewBox="0 0 24 24"
            >
              <GiCookingPot className="icons_loading" />
            </svg>
          </div>
        </>
      )}
    </>
  );
};
export default MenuOrder;
