import React from "react";
import GoogleMapReact from "google-map-react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FC } from "react";

// @ts-ignore
const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "white",
      background: "#DA271B",
      fontSize: "0.8rem",
      fontStyle: "bold",
      padding: "10px 8px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

interface LocationProps {
  style: any;
  color: string;
}

const LocationsMap: FC<LocationProps> = ({ color }) => {
  const defaultProps = {
    center: {
      lat: 61.42832,
      lng: 10.44314,
    },
    zoom: 6,
  };
  return (
    <Container>
      <div className="pb-10">
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 12 }}>
            <div className="pb-1 px-2">
              <h1 className="lg:px-20 md:px-20  lg:py-8 py-10 font-montserrat text-4xl">
                Du finner oss her
              </h1>
              <h3 className="lg:px-20  font-montserrat">TRONDHEIM</h3>
              <Card className="lg:p-9 p-6 md:ml-20 md:mr-20  mt-4 !bg-beige text-white !border-beige">
                <Card.Title className="font-bold pb-2 tracking-wider">
                  Skansen Catering
                </Card.Title>
                <Card.Subtitle className="pb-2">
                  Bromstadvegen 57, 7047 Trondheim
                </Card.Subtitle>
              </Card>
              <h3 className="lg:px-20 pt-6 font-montserrat">TØNSBERG</h3>
              <Card className="lg:p-9 p-6 md:ml-20 md:mr-20  mt-4 !bg-beige text-white !border-beige">
                <Card.Title className="font-bold pb-2 tracking-wider">
                  Peking Kafe & Restaurant
                </Card.Title>
                <Card.Subtitle className="pb-2">
                  Grev Wedels gate 4B, 3111 Tønsberg
                </Card.Subtitle>
              </Card>
              <h3 className="lg:px-20 pt-6 font-montserrat">STAVANGER</h3>
              <Card className="lg:p-9 p-6 md:ml-20 md:mr-20  mt-4 !bg-beige text-white !border-beige">
                <Card.Title className="font-bold pb-2 tracking-wider">
                  Matboden Rogaland
                </Card.Title>
                <Card.Subtitle className="pb-2">
                Timoteiveien 22, 4017 Stavanger
                </Card.Subtitle>
              </Card>
            </div>
          </Col>
          <Col md={{ span: 5, offset: 0 }} xs={{ span: 12, offset: 0 }}>
            <div className="pt-10 md:pb-12 px-2 pb-1">
              <div style={{ height: "85vh", width: "100%" }}>
                <GoogleMapReact
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <AnyReactComponent
                    // @ts-ignore
                    lat={63.42489097639901}
                    lng={10.46383176774952}
                    text="Skansen Catering"
                  />
                  <AnyReactComponent
                    // @ts-ignore
                    lat={59.27033810693411}
                    lng={10.40929013662223}
                    text="Peking Kafe & Restaurant"
                  />
                  <AnyReactComponent
                    // @ts-ignore
                    lat={58.9347325}
                    lng={5.7387753}
                    text="Matboden Rogaland"
                  />
                </GoogleMapReact>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default LocationsMap;
