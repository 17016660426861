//@ts-nocheck
import {
  Row,
  Form,
  InputGroup,
  Col,
  Button,
  Container,
  FormControl,
} from "react-bootstrap";
import { DocumentData, collection, getDocs, query } from "firebase/firestore";

import React from "react";
import { useState, useEffect } from "react";
import ModalComponent from "../components/ModalComponent.tsx";
import { app, db } from "../firebase.ts";
import { getFunctions, httpsCallable } from "firebase/functions";

import MyOrders from "../components/MyOrders.tsx";

const FindYourOrder = () => {
  const [phonenumber, setPhonenumber] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const [verifyCode, setVerifyCode] = useState(0);
  const [verifyInputCode, setVerifyInputCode] = useState(0);
  const [validate, setValidate] = useState("notChecked");

  const functions = getFunctions(app);
  const verifyPhoneNumber = httpsCallable(functions, "verifyNumber");

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function vertifyNumber(number: string) {
    let phonenumber = number;
    let data = {
      phonenumber: phonenumber,
    };
    verifyPhoneNumber(data).then((response) => {
      setVerifyCode(response.data);
    });
  }

  const checkVerifyCode = () => {
    if (verifyCode === verifyInputCode) {
      setValidate("true");
      fetchData();
      setShow(false);
    } else {
      setValidate("false");
      setShow(false);
    }
  };

  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "sales"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        if (
          doc.data().phonenumber === phonenumber &&
          doc.data().paymentStatus === "paid"
        ) {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setPhonenumber(message);
    setShow(true);
  };

  function capitalize(s: string) {
    return s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
  }

  useEffect(() => {
    if (phonenumber !== "") {
      vertifyNumber(phonenumber);
    }
  }, [phonenumber]);

  useEffect(() => {
    if (show === true) {
      setData([]);
      setValidate("notChecked");
    }
  }, [show]);

  return (
    <>
      <div className="grid grid-cols-[repeat(auto-fit,_100%)] m-auto p-2 justify-center items-center place-items-center">
        <div className="font-montserrat lg:text-4xl text-3xl w-full p-8 col-span-1 justify-center justify-self-center mx-auto text-center">
          Finn din bestilling
        </div>

        <Form className="md:w-96">
          <Container className="mt-6 mb-6">
            <InputGroup>
              <Form.Control
                placeholder="Telefonnummer"
                aria-describedby="basic-addon2"
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                className="tracking-wide lg:ml-2 !bg-button !border-button"
                onClick={handleClick}
              >
                Finn bestilling
              </Button>
            </InputGroup>
          </Container>
        </Form>
      </div>
      {validate === "false" && <p>Feil vertifiseringskode</p>}

      {data.length !== 0 ? (
        <div className="place-items-center">
          <Row className="justify-content-md-center px-2">
            {data.map((item) => (
              <Col md={{ span: 5 }} className="g-4">
                <MyOrders
                  status={item.status}
                  key={item.id}
                  date={capitalize(
                    new Date(item.delivery.date.seconds * 1000).toLocaleString(
                      `no-NO`,
                      options
                    )
                  )}
                  time={new Date(
                    item.delivery.time.seconds * 1000
                  ).toLocaleTimeString(`no-NO`, {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  menuId={item.menuName}
                  packageType={item.menuName}
                  totalPrice={item.totalPrice}
                  orderId={item.id}
                  phoneNumber={phonenumber}
                  orderIdLink={item.id}
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
      {validate === "false" ? (
        <div className="mb-10 ml-2 mr-2">
          Valideringskoden du skrev inn matchet ikke med den som ble sendt på
          melding. Vennligst prøv igjen.
        </div>
      ) : null}

      {validate === "true" && data.length === 0 ? (
        <>
          <Container>
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <div className="px-2 pt-8">
                  Vi finner dessverre ingen bestillinger lagt inn med nummeret
                  oppgitt her: <strong>{phonenumber}</strong>.
                </div>
                <br></br>
                <div className="px-2 mb-10">
                  Vennligst sjekk at du har skrevet inn riktig nummer, det som
                  ble oppgitt under bestilling, eller ta kontakt med oss.
                </div>
              </Col>
            </Row>
          </Container>
        </>
      ) : null}

      {/** Modal for å vertifisere*/}
      <ModalComponent
        show={show}
        title="Verifisering av mobilnummer"
        footer={
          <Button
            variant="secondary"
            className="text-white bg-[#24292e] text-left ml-16 mt-3"
            onClick={() => setShow(false)}
          >
            Lukk
          </Button>
        }
        body={
          <>
            <InputGroup>
              <Container className="mt-6 mb-6">
                <Row>
                  <div className="text-md tracking-wider font-weight-400">
                    Du vil nå motta en verifiseringskode på SMS, skriv inn koden
                    her: (kan ta opptil 30 sekunder)
                  </div>
                  <Col
                    lg={{ span: 8, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <FormControl
                      placeholder="Skriv inn verifiseringskode mottatt på SMS"
                      aria-describedby="verify-code"
                      onChange={(e) =>
                        setVerifyInputCode(Number(e.target.value))
                      }
                    />
                  </Col>
                  <Col
                    lg={{ span: 3, order: 0 }}
                    xs={{ span: 12 }}
                    className="mt-4"
                  >
                    <Button
                      className="tracking-wide w-100"
                      style={{
                        backgroundColor: "#DA271B",
                        borderColor: "#DA271B",
                        fontWeight: 400,
                      }}
                      onClick={checkVerifyCode}
                    >
                      Sjekk kode
                    </Button>
                  </Col>
                </Row>
              </Container>
            </InputGroup>
          </>
        }
      />
    </>
  );
};

export default FindYourOrder;
