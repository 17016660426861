//@ts-nocheck
import { Row, Form, InputGroup, Col, Button, Container } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import { SetStateAction } from "react";
import {
  DocumentData,
  query,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase.ts";
import ModalComponent from "../components/ModalComponent.tsx";

const LogIn = () => {
  const [message, setMessage] = useState("");

  const [invalidCode, setInvalidCode] = useState(false);

  async function logIn(supplierId: string) {
    const list: SetStateAction<DocumentData[]> = [];
    const docRef = doc(db, "suppliers", supplierId);
    try {
      const doc = await getDoc(docRef);
      if (doc.exists()) {
        localStorage.setItem("supplierId", supplierId);
        window.location.href = "/admin/6a5ae4d9-ccc9-4a02-9b4d-18ea05745664";
      } else {
        setInvalidCode(true);
      }
    } catch (e) {
      console.log("Error getting document:", e);
    }
  }

  return (
    <>
      <div className="grid grid-cols-[repeat(auto-fit,_100%)] m-auto lg:p-20 p-2 justify-center items-center place-items-center">
        <div className="font-montserrat lg:text-4xl text-3xl w-full p-8 col-span-1 justify-center justify-self-center mx-auto  text-center ">
          Logg inn leverandør
        </div>
        <Form>
          <Container className="mt-6 mb-24">
            <InputGroup>
              <Form.Control
                placeholder="Skriv inn passord"
                aria-describedby="basic-addon2"
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                className="tracking-wide lg:ml-2 !bg-button !border-button"
                onClick={() => logIn(message)}
              >
                Logg inn
              </Button>
            </InputGroup>
          </Container>
        </Form>
      </div>
      {/** Modal hvis validering av nummer ikke var korrekt */}
      <ModalComponent
        show={invalidCode}
        title="Feil eller ugyldig kode"
        hide={() => setInvalidCode(false)}
        footer={
          <Button
            variant="secondary"
            className="text-white !bg-[#24292e] text-left ml-16 mt-3"
            onClick={() => {
              setMessage(" ");
              setInvalidCode(false);
            }}
          >
            Lukk
          </Button>
        }
        body={
          <>
            Kode som ble skrevet inn er enten feil eller ikke gyldig. Vennligst
            prøv igjen.
          </>
        }
      />
    </>
  );
};

export default LogIn;
