//@ts-nocheck
import { FC } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import React from "react";

import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

interface ContactPersonComponentProps {
  name?: string;
  phone?: number;
  email?: number;
  alertEmail?: boolean;
  alertSms?: boolean;
  onClickChangeEmail?: () => void;
  onClickChangeSms?: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
}

const ContactPersonComponent: FC<ContactPersonComponentProps> = ({
  name = "",
  phone = "",
  email = "",
  alertEmail = false,
  alertSms = false,
  onClickChangeEmail,
  onClickChangeSms,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <div>
      <Container className="py-2 h-30">
        <Row xs={2}>
          <Col xs={7} md={3}>
            <div className="text-md pt-2 ml-2">{name}</div>
          </Col>
          <Col xs={3} md={2}>
            <div className="text-md pt-2 ml-2">{phone}</div>
          </Col>
          <Col xs={7} md={4}>
            <div className="text-md pt-2 ml-2">{email}</div>
          </Col>
          <Col xs={2} md={1} className="-ml-2">
            <BootstrapSwitchButton
              checked={alertSms}
              onlabel="SMS"
              offlabel="SMS"
              width={62}
              onstyle="success"
              onChange={onClickChangeSms}
            />
          </Col>
          <Col xs={3} md={1}>
            <BootstrapSwitchButton
              checked={alertEmail}
              onlabel="Epost"
              offlabel="Epost"
              width={68}
              onstyle="success"
              onChange={onClickChangeEmail}
            />
          </Col>
          <Col xs={2} md={1}>
            <div className="float-right ml-4 flex mt-2.5">
              {/*     <AiFillEdit
                size={24}
                className="mr-3"
                color="#25A2E9"
                onClick={onClickEdit}
              /> */}
              <AiFillCloseCircle
                size={24}
                color="#FF5656"
                onClick={onClickDelete}
              />
            </div>
          </Col>
        </Row>
        <hr
          style={{
            height: "1px",
            borderTop: "2px solid #ccc",
            width: "100%",
            float: "right",
            marginTop: "1rem",
          }}
        ></hr>
      </Container>
    </div>
  );
};

export default ContactPersonComponent;
