//@ts-nocheck
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import om_oss from "../img/om_oss.png";

const AboutUs = () => {
  return (
    <div className="pb-10">
      <Container>
        <Row xs={1} lg={2}>
          <Col className="lg:pt-20 pt-10 lg:mb-20" lg={5}>
            <h1 className="font-montserrat text-4xl"> Om oss </h1>
            <div className="pt-4">
              Hos Kasserolle AS setter vi brukeropplevelsen i sentrum. Vi tilbyr
              deg enkel og sømløs bestilling av catering-tjenester gjennom vår
              digitale distribusjonskanal. Vi samarbeider med de beste
              leverandørene i bransjen for å sikre deg mat av høy kvalitet og
              friske råvarer.
            </div>
            <div className="pt-4">
              Vi vet at det kan være stressende å planlegge en begivenhet, og vi
              ønsker å gjøre det så enkelt som mulig for deg. Derfor har vi
              utviklet en plattform som gjør det lett å bestille catering til
              din neste fest, konferanse eller selskap. Med vårt brede utvalg av
              menyer, kan du enkelt tilpasse maten til dine gjesters behov og
              ønsker.{" "}
            </div>{" "}
            <div className="pt-4">
              Vi har erfarne kokker og catering-eksperter som står klare til å
              hjelpe deg med å planlegge og gjennomføre din begivenhet. Vi tar
              oss av alt fra matlaging til servering, slik at du kan slappe av
              og nyte selskapet sammen med gjestene dine.
            </div>{" "}
            <div className="pt-4">
              Vi er stolte av å kunne tilby deg en personlig og profesjonell
              tjeneste, og vi ser frem til å hjelpe deg med å skape minnerike
              opplevelser for deg og dine gjester. Ta kontakt med oss i dag for
              å bestille catering til din neste begivenhet!
            </div>
          </Col>
          <Col
            className="pt-6 lg:mt-16 lg:pb-20 mb-10 lg:pl-24 lg:pr-16"
            lg={7}
          >
            <img src={om_oss} alt="" className="rounded-full pt-2 ml-2" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
