//@ts-nocheck
import { Row, Col, Nav, Navbar } from "react-bootstrap";
import React from "react";
import { useState, useEffect } from "react";
import logo from "../img/kasserolle_logo_rod.png";
import ToggleSimple from "../components/ToggleSimple.tsx";

import { collection, DocumentData, getDocs, query } from "firebase/firestore";
import { db } from "../firebase.ts";

const NavBar = () => {
  const [data, setData] = useState<DocumentData[]>([]);
  const fetchData = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "dynamicPages"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        console.log(doc.data());
        if (doc.id === "newsbanner") {
          list.push({ id: doc.id, ...doc.data() });
        }
      });
      await setData([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <>
      {data ? (
        <>
          {data.map((doc) => {
            return (
              <>
                {doc.active === true ? (
                  <div className="bg-[#c1b6ab] py-3 grid  items-end align-right content-end lg:pr-7">
                    <Row className="flex float-right">
                      <Col className="flex justify-content-center">
                        <div className="lg:flex">
                          <div className="text-footer font-medium tracking-wider lg:pt-0.5 px-2 ">
                            {doc.description}{" "}
                          </div>{" "}
                          <div className="text-footer text-lg font-bold tracking-wider pl-2 lg:mr-0 mr-4">
                            {" "}
                            {doc.promocode}
                          </div>
                        </div>
                        <div className="text-white font-medium tracking-wider"></div>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </>
            );
          })}
        </>
      ) : null}
      <div className="bg-footer py-2 grid  items-end align-right content-end lg:pr-7">
        <Row className="flex float-right">
          <Col md={{ offset: 11 }} xs={{ offset: 8 }}>
            <ToggleSimple
              onClick={(e) => {
                localStorage.setItem("location", e.currentTarget.textContent);
                window.location.reload();
              }}
            />
          </Col>
        </Row>
      </div>
      <Navbar
        collapseOnSelect
        expand="xl"
        bg="#FDF9F3"
        variant="light"
        className="w-100 gap-3 px-4"
      >
        <Navbar.Brand href="/">
          {window.innerWidth < 960 ? (
            <div>
              <Row>
                <Col>
                  <img
                    alt="logo_mobil"
                    className="h-20 -mt-1 -mb-3 -ml-2 w-20 pr-1"
                    src={logo}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row>
                <Col>
                  <img alt="logo_mobil" className="w-20 ml-6 mt-2" src={logo} />
                </Col>
                <Col className="g-0">
                  <div className="font-montserrat text-2xl mt-10 ml-2">
                    Kasserolle.no
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Navbar.Brand>
        {window.innerWidth < 960 ? (
          <Nav.Link
            className="bg-button text-white rounded-md px-2 h-10 pt-2 w-34 -ml-1 font-montserrat focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform"
            href={"/finn-bestilling"}
            style={{ fontWeight: 400 }}
          >
            Min bestilling
          </Nav.Link>
        ) : null}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto justify-content-end flex-grow-1  gap-3 px-3">
            <Nav.Link href={"/menyer"} className="mt-2">
              Våre menyer
            </Nav.Link>
            <Nav.Link href={"/hvor-finner-du-oss"} className="mt-2">
              Her finner du oss
            </Nav.Link>
            <Nav.Link href={"/om-oss"} className="mt-2">
              Om oss
            </Nav.Link>
            <Nav.Link href={"/kontakt"} className="mt-2">
              Kontakt oss
            </Nav.Link>
            {window.innerWidth > 960 ? (
              <Nav.Link
                className="!bg-button text-white rounded-3xl px-3 h-14 pt-3 float-right focus:ring-4 shadow-sm transform active:scale-y-75 transition-transform"
                style={{ fontWeight: 500 }}
                onClick={(e) => {
                  //let randomId = makeid(10);
                  window.location.href = "/finn-bestilling"; // `https://api.vipps.no/access-management-1.0/access/oauth2/auth?client_id=917d9000-a9ea-4f21-8656-9b3142a659a7&response_type=code&scope=openid%20name%20phoneNumber%20address%20birthDate&state=${randomId}&redirect_uri=http://localhost:3000/landingsside`;
                }}
              >
                Min bestilling
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
